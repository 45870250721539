/*  Template Name: Landrick - Saas & Software Bootstrap 5 Landing & Admin Dashboard Template
    Author: Shreethemes
    E-mail: support@shreethemes.in
    Created: August 2019
    Version: 4.2.0
    File: Main Css File
*/
@import url("https://fonts.googleapis.com/css?family=Nunito:300,400,500,600,700&display=swap");
body {
  font-family: "Nunito", sans-serif;
  overflow-x: hidden !important;
  font-size: 1rem;
  color: #f8f9fa;
  background-color: #1c2836;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: var(--bs-font-sans-serif);
  line-height: 1.4;
  font-weight: 600;
}

::selection {
  background: rgba(47, 85, 212, 0.9);
  color: #ffffff;
}

a {
  text-decoration: none !important;
  transition: all 0.5s ease;
}

p {
  line-height: 1.6;
}

/*********************************/
/*         Menu                  */
/*===============================*/
#topnav {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  z-index: 999;
  background-color: transparent;
  border: 0;
  transition: all 0.5s ease;
}
#topnav .logo {
  float: left;
  color: #f8f9fa !important;
}
#topnav .logo .l-dark,
#topnav .logo .logo-dark-mode {
  display: none;
}
#topnav .logo .l-light,
#topnav .logo .logo-light-mode {
  display: inline-block;
}
#topnav .logo:focus {
  outline: none;
}
#topnav .has-submenu.active a {
  color: #ffffff;
}
#topnav .has-submenu.active .submenu li.active > a {
  color: #2f55d4 !important;
}
#topnav .has-submenu.active.active .menu-arrow {
  border-color: #2f55d4;
}
#topnav .has-submenu {
  position: relative;
}
#topnav .has-submenu .submenu {
  position: relative;
}
#topnav .has-submenu .submenu .submenu-arrow {
  border: solid #f8f9fa;
  border-radius: 0.5px;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  position: absolute;
  right: 20px;
  top: 13px;
}
#topnav .has-submenu .submenu .has-submenu .submenu .has-submenu:hover .submenu-arrow {
  border-color: #2f55d4;
}
#topnav .has-submenu .submenu .has-submenu:hover > .submenu-arrow {
  border-color: #2f55d4;
}
#topnav .navbar-toggle {
  border: 0;
  position: relative;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
#topnav .navbar-toggle .lines {
  width: 25px;
  display: block;
  position: relative;
  margin: 30px 0 26px 10px;
  height: 18px;
}
#topnav .navbar-toggle span {
  height: 2px;
  width: 100%;
  background-color: #f8f9fa;
  display: block;
  margin-bottom: 5px;
  transition: transform 0.5s ease;
}
#topnav .navbar-toggle span:last-child {
  margin-bottom: 0;
}
#topnav .buy-button {
  float: right;
  line-height: 74px;
}
#topnav .buy-button > li {
  line-height: initial;
}
#topnav .buy-button .login-btn-primary,
#topnav .buy-button .btn-icon-dark {
  display: none;
}
#topnav .buy-button .login-btn-light,
#topnav .buy-button .btn-icon-light {
  display: inline-block;
}
#topnav .buy-button .search-bar .menu-search form {
  position: relative;
}
#topnav .buy-button .search-bar .searchform input[type=text] {
  box-shadow: none;
  padding: 10px 12px;
  height: 44px;
  font-size: 14px;
  display: block;
  outline: none !important;
  padding-right: 45px;
}
#topnav .buy-button .search-bar .searchform input[type=submit] {
  display: none;
}
#topnav .buy-button .search-bar .searchform:after {
  content: "\f0349";
  position: absolute;
  font-family: "Material Design Icons";
  right: 14px;
  top: 14px;
  font-size: 20px;
  line-height: 20px;
  pointer-events: none;
}
#topnav .buy-button .dropdown .dropdown-toggle:after,
#topnav .buy-menu-btn .dropdown .dropdown-toggle:after {
  display: none;
}
#topnav .navbar-toggle.open span {
  position: absolute;
}
#topnav .navbar-toggle.open span:first-child {
  top: 6px;
  transform: rotate(45deg);
}
#topnav .navbar-toggle.open span:nth-child(2) {
  visibility: hidden;
}
#topnav .navbar-toggle.open span:last-child {
  width: 100%;
  top: 6px;
  transform: rotate(-45deg);
}
#topnav .navbar-toggle.open span:hover {
  background-color: #2f55d4;
}
#topnav .navbar-toggle:hover, #topnav .navbar-toggle:focus,
#topnav .navbar-toggle .navigation-menu > li > a:hover, #topnav .navbar-toggle:focus {
  background-color: transparent;
}
#topnav .navigation-menu {
  list-style: none;
  margin: 0;
  padding: 0;
}
#topnav .navigation-menu > li {
  float: left;
  display: block;
  position: relative;
  margin: 0 10px;
}
#topnav .navigation-menu > li:hover > a, #topnav .navigation-menu > li.active > a {
  color: #2f55d4 !important;
}
#topnav .navigation-menu > li > a {
  display: block;
  color: #f8f9fa;
  font-size: 13px;
  background-color: transparent !important;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 24px;
  text-transform: uppercase;
  font-family: var(--bs-font-sans-serif);
  padding-left: 15px;
  padding-right: 15px;
}
#topnav .navigation-menu > li > a:hover, #topnav .navigation-menu > li > a:active {
  color: #2f55d4;
}
#topnav .navigation-menu > li .submenu.megamenu li .megamenu-head {
  padding: 10px 20px;
  white-space: nowrap;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  font-weight: 700;
  color: #fff !important;
}
#topnav .navigation-menu .has-submenu .menu-arrow {
  border: solid #f8f9fa;
  border-radius: 0.5px;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  position: absolute;
  transition: all 0.5s;
  right: -1px;
  top: 30px;
}
#topnav .navigation-menu .has-submenu:hover .menu-arrow {
  transform: rotate(225deg);
}
#topnav .menu-extras {
  float: right;
}
#topnav.scroll {
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 3px rgba(173, 181, 189, 0.15);
}
#topnav.scroll .navigation-menu > li > a {
  color: #f8f9fa;
}
#topnav.scroll .navigation-menu > li > .menu-arrow {
  border-color: #f8f9fa;
}
#topnav.scroll .navigation-menu > li:hover > a, #topnav.scroll .navigation-menu > li.active > a {
  color: #2f55d4;
}
#topnav.scroll .navigation-menu > li:hover > .menu-arrow, #topnav.scroll .navigation-menu > li.active > .menu-arrow {
  border-color: #2f55d4;
}
#topnav.defaultscroll.dark-menubar .logo {
  line-height: 70px;
}
#topnav.defaultscroll.scroll .logo {
  line-height: 62px;
}
#topnav.defaultscroll.scroll.dark-menubar .logo {
  line-height: 62px;
}
#topnav.nav-sticky {
  background: #1c2836;
  box-shadow: 0 0 3px rgba(173, 181, 189, 0.15);
}
#topnav.nav-sticky .navigation-menu.nav-light > li > a {
  color: #f8f9fa;
}
#topnav.nav-sticky .navigation-menu.nav-light > li.active > a {
  color: #2f55d4 !important;
}
#topnav.nav-sticky .navigation-menu.nav-light > li:hover > .menu-arrow, #topnav.nav-sticky .navigation-menu.nav-light > li.active > .menu-arrow {
  border-color: #2f55d4 !important;
}
#topnav.nav-sticky .navigation-menu.nav-light > li:hover > a, #topnav.nav-sticky .navigation-menu.nav-light > li.active > a {
  color: #2f55d4 !important;
}
#topnav.nav-sticky .navigation-menu.nav-light .has-submenu .menu-arrow {
  border-color: #f8f9fa;
}
#topnav.nav-sticky.tagline-height {
  top: 0 !important;
}
#topnav.nav-sticky .buy-button .login-btn-primary,
#topnav.nav-sticky .buy-button .btn-icon-dark {
  display: inline-block;
}
#topnav.nav-sticky .buy-button .login-btn-light,
#topnav.nav-sticky .buy-button .btn-icon-light {
  display: none;
}
#topnav.nav-sticky .logo .l-dark {
  display: inline-block;
}
#topnav.nav-sticky .logo .l-light {
  display: none;
}

.logo {
  font-weight: 700;
  font-size: 24px;
  margin-right: 15px;
  padding: 0 0 6px;
  letter-spacing: 1px;
  line-height: 68px;
}

@media (min-width: 1025px) {
  #topnav .navigation-menu > li .submenu.megamenu {
    width: 1116px !important;
  }
}
@media screen and (max-width: 1024px) and (min-width: 992px) {
  #topnav .navigation-menu > li .submenu.megamenu {
    width: 936px !important;
  }
}
@media (min-width: 992px) {
  #topnav .navigation-menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  #topnav .navigation-menu > .has-submenu:hover .menu-arrow {
    top: 33px !important;
  }
  #topnav .navigation-menu > .has-submenu.active .menu-arrow {
    top: 30px;
  }
  #topnav .navigation-menu > li .submenu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    padding: 15px 0;
    list-style: none;
    min-width: 180px;
    visibility: hidden;
    opacity: 0;
    margin-top: 10px;
    transition: all 0.2s ease;
    border-radius: 6px;
    background-color: #1c2836;
    box-shadow: 0 0 3px rgba(173, 181, 189, 0.15);
  }
  #topnav .navigation-menu > li .submenu li {
    position: relative;
  }
  #topnav .navigation-menu > li .submenu li a {
    display: block;
    padding: 10px 20px;
    clear: both;
    white-space: nowrap;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    font-weight: 700;
    color: #f8f9fa !important;
    transition: all 0.3s;
  }
  #topnav .navigation-menu > li .submenu li a:hover {
    color: #2f55d4 !important;
  }
  #topnav .navigation-menu > li .submenu li ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
  }
  #topnav .navigation-menu > li .submenu.megamenu {
    white-space: nowrap;
    left: 50%;
    transform: translateX(-50%);
    position: fixed;
    top: auto;
    display: flex;
  }
  #topnav .navigation-menu > li .submenu.megamenu > li {
    overflow: hidden;
    vertical-align: top;
    width: 20%;
  }
  #topnav .navigation-menu > li .submenu.megamenu > li .submenu {
    left: 100%;
    top: 0;
    margin-left: 10px;
    margin-top: -1px;
  }
  #topnav .navigation-menu > li .submenu > li .submenu {
    left: 101%;
    top: 0;
    margin-left: 10px;
    margin-top: -1px;
  }
  #topnav .navigation-menu > li > a {
    padding-top: 25px;
    padding-bottom: 25px;
    min-height: 62px;
  }
  #topnav .navigation-menu > li:hover > .menu-arrow {
    border-color: #2f55d4;
  }
  #topnav .navigation-menu > li:hover > a, #topnav .navigation-menu > li.active > a {
    color: #2f55d4 !important;
  }
  #topnav .navigation-menu > li.last-elements .submenu {
    left: auto;
    right: 0;
  }
  #topnav .navigation-menu > li.last-elements .submenu:before {
    left: auto;
    right: 10px;
  }
  #topnav .navigation-menu > li.last-elements .submenu > li.has-submenu .submenu {
    left: auto;
    right: 100%;
    margin-left: 0;
    margin-right: 10px;
  }
  #topnav .navigation-menu.nav-light > li > a {
    color: rgba(255, 255, 255, 0.5);
  }
  #topnav .navigation-menu.nav-light > li.active > a {
    color: #ffffff !important;
  }
  #topnav .navigation-menu.nav-light > li:hover > .menu-arrow {
    border-color: #ffffff !important;
  }
  #topnav .navigation-menu.nav-light > li:hover > a {
    color: #ffffff !important;
  }
  #topnav .navigation-menu.nav-light .has-submenu .menu-arrow {
    border-color: rgba(255, 255, 255, 0.5);
  }
  #topnav .navigation-menu.nav-light .has-submenu.active .menu-arrow {
    border-color: #ffffff !important;
  }
  #topnav .navigation-menu.nav-right {
    justify-content: flex-end !important;
  }
  #topnav .navigation-menu.nav-left {
    justify-content: flex-start !important;
  }
  #topnav .navigation-menu.nav-left > li.last-elements .submenu {
    left: 0 !important;
    right: auto !important;
  }
  #topnav .navigation-menu.nav-left > li.last-elements .submenu:before {
    left: 45px !important;
    right: auto !important;
  }
  #topnav .buy-button {
    padding-left: 15px;
    margin-left: 15px;
  }
  #topnav .navbar-toggle {
    display: none;
  }
  #topnav #navigation {
    display: block !important;
  }
  #topnav.scroll {
    top: 0;
  }
  #topnav.scroll .navigation-menu > li > a {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  #topnav.scroll-active .navigation-menu > li > a {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}
@media (max-width: 991px) {
  #topnav {
    background-color: #1c2836;
    box-shadow: 0 0 3px rgba(173, 181, 189, 0.15);
    min-height: 74px;
  }
  #topnav .logo .l-dark {
    display: inline-block !important;
  }
  #topnav .logo .l-light {
    display: none !important;
  }
  #topnav .container {
    width: auto;
  }
  #topnav #navigation {
    max-height: 400px;
  }
  #topnav .navigation-menu {
    float: none;
  }
  #topnav .navigation-menu > li {
    float: none;
  }
  #topnav .navigation-menu > li .submenu {
    display: none;
    list-style: none;
    padding-left: 20px;
    margin: 0;
  }
  #topnav .navigation-menu > li .submenu li a {
    display: block;
    position: relative;
    padding: 7px 15px;
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 0.04em;
    font-weight: 700;
    color: #f8f9fa !important;
    transition: all 0.3s;
  }
  #topnav .navigation-menu > li .submenu.megamenu li .megamenu-head {
    padding: 7px 15px;
  }
  #topnav .navigation-menu > li .submenu.open {
    display: block;
  }
  #topnav .navigation-menu > li .submenu .submenu {
    display: none;
    list-style: none;
  }
  #topnav .navigation-menu > li .submenu .submenu.open {
    display: block;
  }
  #topnav .navigation-menu > li .submenu.megamenu > li > ul {
    list-style: none;
    padding-left: 0;
  }
  #topnav .navigation-menu > li .submenu.megamenu > li > ul > li > span {
    display: block;
    position: relative;
    padding: 10px 15px;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 2px;
    color: #9bacc4;
  }
  #topnav .navigation-menu > li > a {
    color: #f8f9fa;
    padding: 10px 20px;
  }
  #topnav .navigation-menu > li > a:after {
    position: absolute;
    right: 15px;
  }
  #topnav .navigation-menu > li > a:hover,
#topnav .navigation-menu > li .submenu li a:hover,
#topnav .navigation-menu > li.has-submenu.open > a {
    color: #2f55d4;
  }
  #topnav .menu-extras .menu-item {
    border-color: #9bacc4;
  }
  #topnav .navbar-header {
    float: left;
  }
  #topnav .buy-button .login-btn-primary,
#topnav .buy-button .btn-icon-dark {
    display: inline-block !important;
  }
  #topnav .buy-button .login-btn-light,
#topnav .buy-button .btn-icon-light {
    display: none;
  }

  #topnav .has-submenu .submenu .submenu-arrow {
    transform: rotate(45deg);
    position: absolute;
    right: 20px;
    top: 12px;
  }
  #topnav .has-submenu.active a {
    color: #2f55d4;
  }

  #navigation {
    position: absolute;
    top: 74px;
    left: 0;
    width: 100%;
    display: none;
    height: auto;
    padding-bottom: 0;
    overflow: auto;
    border-top: 1px solid #1c2836;
    border-bottom: 1px solid #1c2836;
    background-color: #1c2836;
    box-shadow: 0 0 3px rgba(173, 181, 189, 0.15);
  }
  #navigation.open {
    display: block;
    overflow-y: auto;
  }
}
@media (max-width: 768px) {
  #topnav .navigation-menu .has-submenu .menu-arrow {
    right: 8px;
    top: 16px;
  }
}
@media (min-width: 768px) {
  #topnav .navigation-menu > li.has-submenu:hover > .submenu {
    visibility: visible;
    opacity: 1;
    margin-top: 0;
  }
  #topnav .navigation-menu > li.has-submenu:hover > .submenu > li.has-submenu:hover > .submenu {
    visibility: visible;
    opacity: 1;
    margin-left: 0;
    margin-right: 0;
  }
  #topnav .navigation-menu > li.has-submenu:hover > .submenu > li.has-submenu:hover > .submenu > li:hover > .submenu {
    visibility: visible;
    opacity: 1;
    margin-left: 0;
    margin-right: 0;
  }

  .navbar-toggle {
    display: block;
  }
}
@media (max-width: 425px) {
  #topnav .buy-menu-btn {
    display: block !important;
    margin: 0 10px;
    padding: 10px 20px;
  }
  #topnav .buy-menu-btn .dropdown .dropdown-menu.show {
    transform: translate3d(0px, -54px, 0px) !important;
  }
}
.tagline {
  position: absolute;
  width: 100%;
  z-index: 99;
  font-size: 14px;
  padding: 13px 0;
}
@media screen and (max-width: 575px) {
  .tagline {
    display: none;
  }
}

@media screen and (max-width: 575px) {
  .tagline-height {
    top: 0px !important;
  }
}
@media (min-width: 576px) {
  .tagline-height {
    top: 47px !important;
  }
}

.sidebar-nav > .navbar-item {
  padding: 5px 0;
}
.sidebar-nav > .navbar-item .navbar-link {
  color: #f8f9fa !important;
  font-size: 15px;
  font-weight: 600;
}
.sidebar-nav > .navbar-item .navbar-link .navbar-icon {
  font-size: 18px;
  margin-right: 6px;
}
.sidebar-nav > .navbar-item:hover .navbar-link, .sidebar-nav > .navbar-item.active .navbar-link {
  color: #2f55d4 !important;
}

#navmenu-nav li.active a {
  color: #2f55d4 !important;
}
#navmenu-nav li.account-menu.active .navbar-link, #navmenu-nav li.account-menu:hover .navbar-link {
  color: #ffffff !important;
  background-color: #2f55d4 !important;
}

.navbar-white-bg {
  background-color: #1c2836 !important;
}

/*********************************/
/*         Footer                */
/*===============================*/
.footer {
  background: #212f40;
  position: relative;
  color: #adb5bd;
}
.footer .footer-py-60 {
  padding: 60px 0;
}
.footer .footer-py-30 {
  padding: 30px 0;
}
.footer .logo-footer {
  font-size: 22px;
}
.footer .logo-footer:focus {
  outline: none;
}
.footer .footer-head {
  letter-spacing: 1px;
  font-weight: 500;
  color: #fff;
}
.footer .foot-subscribe .form-control {
  background-color: #253447;
  border: 1px solid #253447;
  color: #dee2e6 !important;
}
.footer .foot-subscribe .form-control:focus {
  box-shadow: none;
}
.footer .foot-subscribe.foot-white .form-control {
  color: #adb5bd;
}
.footer .foot-subscribe input::placeholder {
  color: #adb5bd;
}
.footer .text-foot {
  color: #adb5bd;
}
.footer .footer-list {
  margin-bottom: 0;
}
.footer .footer-list li {
  margin-bottom: 10px;
}
.footer .footer-list li a {
  transition: all 0.5s ease;
}
.footer .footer-list li a:hover {
  color: #e6e8ea;
}
.footer .footer-list li:last-child {
  margin-bottom: 0;
}
.footer .footer-border,
.footer .footer-bar {
  border-top: 1px solid #2a3b51;
}
.footer .border {
  border-color: #2a3b51 !important;
}
.footer.footer-light {
  background: #1f2d3d;
  color: #9bacc4;
}
.footer.footer-light .footer-head {
  color: #f8f9fa;
}
.footer.footer-light .foot-subscribe .form-control {
  background-color: #1f2d3d;
  border: 1px solid #2a3c51;
  color: #1f2d3d;
}
.footer.footer-light .foot-subscribe.foot-white .form-control {
  color: #adb5bd;
}
.footer.footer-light .foot-subscribe input::placeholder {
  color: #adb5bd;
}
.footer.footer-light .border {
  border-color: #2a3c51 !important;
}
.footer.footer-light .text-foot {
  color: #9bacc4;
}
.footer.footer-light .footer-list li a:hover {
  color: #dee2e6;
}

.avatar.avatar-coin, .avatar.avatar-sm-sm {
  height: 36px;
  width: 36px;
}
.avatar.avatar-ex-sm {
  max-height: 25px;
}
.avatar.avatar-ex-small {
  height: 36px;
  width: 36px;
}
.avatar.avatar-md-sm {
  height: 45px;
  width: 45px;
}
.avatar.avatar-small {
  height: 65px;
  width: 65px;
}
.avatar.avatar-md-md {
  height: 80px;
  width: 80px;
}
.avatar.avatar-medium {
  height: 110px;
  width: 110px;
}
.avatar.avatar-large {
  height: 140px;
  width: 140px;
}
.avatar.avatar-ex-large {
  height: 180px;
  width: 180px;
}

.accordion .accordion-item {
  overflow: hidden;
  border: 0;
  box-shadow: 0 0 3px rgba(173, 181, 189, 0.15);
}
.accordion .accordion-item .accordion-button {
  font-weight: 600;
  text-align: left;
  box-shadow: none;
  padding: 1rem 2.5rem 1rem 1.25rem;
}
.accordion .accordion-item .accordion-button:focus {
  box-shadow: none;
}
.accordion .accordion-item .accordion-button:before {
  content: "\f0143";
  display: block;
  font-family: "Material Design Icons";
  font-size: 16px;
  color: #2f55d4;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.5s ease;
}
.accordion .accordion-item .accordion-button.collapsed {
  background-color: #ffffff;
  color: #f8f9fa !important;
  transition: all 0.5s;
}
.accordion .accordion-item .accordion-button.collapsed:before {
  top: 15px;
  transform: rotate(180deg);
  color: #f8f9fa !important;
}
.accordion .accordion-item .accordion-button:not(.collapsed) {
  color: #2f55d4;
}
.accordion .accordion-item .accordion-button:after {
  display: none;
}

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(45deg, #ffffff, #ffffff);
  z-index: 9999999;
}
#preloader #status {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
#preloader #status .spinner {
  width: 40px;
  height: 40px;
  position: relative;
  margin: 100px auto;
}
#preloader #status .spinner .double-bounce1, #preloader #status .spinner .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #2f55d4;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  animation: sk-bounce 2s infinite ease-in-out;
}
#preloader #status .spinner .double-bounce2 {
  animation-delay: -1s;
}

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
.form-label,
.form-check-label {
  font-weight: 700;
  font-size: 14px;
}

.form-control {
  border: 1px solid #2a3c51;
  font-size: 14px;
  line-height: 26px;
  border-radius: 6px;
  color: #f8f9fa !important;
  text-align: left;
}
.form-control:focus {
  border-color: #2f55d4;
  box-shadow: none;
}
.form-control[readonly] {
  background-color: #ffffff;
}
.form-control:disabled {
  background-color: #495057;
}
.form-control::placeholder {
  color: #dee2e6;
}

.form-check-input {
  border: 1px solid #2a3c51;
  background-color: #1c2836;
}
.form-check-input:focus {
  border-color: #2f55d4;
  box-shadow: none;
}
.form-check-input.form-check-input:checked {
  background-color: #2f55d4;
  border-color: #2f55d4;
}

.subcribe-form .btn {
  padding: 10px 20px;
}
.subcribe-form input {
  padding: 12px 20px;
  width: 100%;
  color: #f8f9fa !important;
  border: none;
  outline: none !important;
  padding-right: 160px;
  padding-left: 30px;
  background-color: rgba(28, 40, 54, 0.8);
  height: 50px;
}
.subcribe-form input::placeholder {
  color: #dee2e6;
}
.subcribe-form button {
  position: absolute;
  top: 2px;
  right: 3px;
  outline: none !important;
}
.subcribe-form form {
  position: relative;
  max-width: 600px;
  margin: 0px auto;
}

/*********************************/
/*         Blog & News           */
/*===============================*/
.blog.blog-primary .tag {
  background: #2f55d4 !important;
}
.blog.blog-primary .title:hover,
.blog.blog-primary .readmore:hover {
  color: #2f55d4 !important;
}
.blog.blog-primary .link {
  color: #2f55d4 !important;
}

.blog.blog-secondary .tag {
  background: #ced4da !important;
}
.blog.blog-secondary .title:hover,
.blog.blog-secondary .readmore:hover {
  color: #ced4da !important;
}
.blog.blog-secondary .link {
  color: #ced4da !important;
}

.blog.blog-success .tag {
  background: #2eca8b !important;
}
.blog.blog-success .title:hover,
.blog.blog-success .readmore:hover {
  color: #2eca8b !important;
}
.blog.blog-success .link {
  color: #2eca8b !important;
}

.blog.blog-info .tag {
  background: #17a2b8 !important;
}
.blog.blog-info .title:hover,
.blog.blog-info .readmore:hover {
  color: #17a2b8 !important;
}
.blog.blog-info .link {
  color: #17a2b8 !important;
}

.blog.blog-warning .tag {
  background: #f17425 !important;
}
.blog.blog-warning .title:hover,
.blog.blog-warning .readmore:hover {
  color: #f17425 !important;
}
.blog.blog-warning .link {
  color: #f17425 !important;
}

.blog.blog-danger .tag {
  background: #e43f52 !important;
}
.blog.blog-danger .title:hover,
.blog.blog-danger .readmore:hover {
  color: #e43f52 !important;
}
.blog.blog-danger .link {
  color: #e43f52 !important;
}

.blog.blog-light .tag {
  background: #1f2d3d !important;
}
.blog.blog-light .title:hover,
.blog.blog-light .readmore:hover {
  color: #1f2d3d !important;
}
.blog.blog-light .link {
  color: #1f2d3d !important;
}

.blog.blog-dark .tag {
  background: #f8f9fa !important;
}
.blog.blog-dark .title:hover,
.blog.blog-dark .readmore:hover {
  color: #f8f9fa !important;
}
.blog.blog-dark .link {
  color: #f8f9fa !important;
}

.blog.blog-footer .tag {
  background: #212f40 !important;
}
.blog.blog-footer .title:hover,
.blog.blog-footer .readmore:hover {
  color: #212f40 !important;
}
.blog.blog-footer .link {
  color: #212f40 !important;
}

.blog.blog-muted .tag {
  background: #9bacc4 !important;
}
.blog.blog-muted .title:hover,
.blog.blog-muted .readmore:hover {
  color: #9bacc4 !important;
}
.blog.blog-muted .link {
  color: #9bacc4 !important;
}

.blog {
  transition: all 0.3s ease;
}
.blog .content h4 {
  line-height: 1.2;
}
.blog .content .title {
  transition: all 0.5s ease;
}
.blog .content .post-meta .like, .blog .content .post-meta .comments, .blog .content .post-meta .readmore {
  font-size: 15px;
  transition: all 0.5s ease;
}
.blog .content .post-meta .like:hover {
  color: #e43f52 !important;
}
.blog .content .post-meta .comments:hover {
  color: #2eca8b !important;
}
.blog .author,
.blog .teacher,
.blog .preview,
.blog .course-fee {
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: all 0.5s ease;
}
.blog .author {
  top: 5%;
  left: 5%;
}
.blog .author .user,
.blog .author .date,
.blog .author .profession,
.blog .teacher .user,
.blog .teacher .date,
.blog .teacher .profession {
  color: #ffffff;
}
.blog .teacher {
  bottom: 6%;
  left: 5%;
}
.blog .course-fee {
  bottom: -5%;
  right: 5%;
  width: 50px;
  height: 50px;
}
.blog .course-fee .fee {
  line-height: 50px;
}
.blog.blog-detail:hover {
  transform: translateY(0px) !important;
}
.blog .overlay {
  background: #161c2d;
}
.blog:hover {
  transform: translateY(-10px);
}
.blog:hover .overlay {
  opacity: 0.6;
}
.blog:hover .author,
.blog:hover .teacher,
.blog:hover .preview,
.blog:hover .course-fee {
  opacity: 1;
}
.blog .blog-list-meta {
  position: absolute;
  bottom: 24px;
}
.blog .preview {
  color: #ffffff !important;
}

.sidebar .widget .blog-categories li {
  padding-bottom: 10px;
}
.sidebar .widget .blog-categories li:last-child {
  padding-bottom: 0;
}
.sidebar .widget .blog-categories li a, .sidebar .widget .blog-categories li span {
  font-size: 15px;
}
.sidebar .widget .blog-categories li a {
  color: #f8f9fa;
  transition: all 0.5s ease;
}
.sidebar .widget .blog-categories li a:hover {
  color: #2f55d4;
}
.sidebar .widget .post-recent {
  padding-bottom: 15px;
}
.sidebar .widget .post-recent:last-child {
  padding-bottom: 0;
}
.sidebar .widget .post-recent .post-recent-thumb {
  width: 25%;
}
.sidebar .widget .post-recent .post-recent-content {
  width: 75%;
  padding-left: 10px;
}
.sidebar .widget .post-recent .post-recent-content a {
  display: block;
  color: #f8f9fa;
  font-size: 15px;
  transition: all 0.5s ease;
}
.sidebar .widget .post-recent .post-recent-content a:hover {
  color: #2f55d4;
}
.sidebar .widget .post-recent .post-recent-content span {
  font-size: 13px;
}

.tagcloud > a {
  background: #1f2d3d;
  color: #f8f9fa;
  display: inline-block;
  font-size: 9px;
  letter-spacing: 1px;
  margin: 5px 10px 5px 0;
  padding: 8px 12px 7px;
  text-transform: uppercase;
  transition: all 0.5s ease;
  font-weight: 600;
}
.tagcloud > a:hover {
  background: #2f55d4;
  color: #ffffff;
}

.media-list .media .media-heading:hover {
  color: #2f55d4 !important;
}
.media-list .sub-comment {
  border-left: 2px dashed #2a3c51;
}

.blog .overlay,
.job-box .job-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: all 0.5s ease;
}

.job-box.job-primary .position a:hover {
  color: #2f55d4 !important;
}
.job-box.job-primary .company-name:hover {
  color: #2f55d4 !important;
}
.job-box.job-primary .title:hover,
.job-box.job-primary .readmore:hover {
  color: #2f55d4 !important;
}
.job-box.job-primary .icons {
  color: #2f55d4 !important;
}
.job-box.job-primary.candidate-list .name:hover, .job-box.job-primary.company-list .name:hover {
  color: #2f55d4 !important;
}
.job-box.job-primary .bg {
  background-color: #2f55d4 !important;
}
.job-box.job-primary .bg-soft {
  background-color: rgba(47, 85, 212, 0.1) !important;
  border: 1px solid rgba(47, 85, 212, 0.1) !important;
  color: #2f55d4 !important;
}
.job-box.job-primary .btn-soft {
  background-color: rgba(47, 85, 212, 0.05) !important;
  border: 1px solid rgba(47, 85, 212, 0.05) !important;
  color: #2f55d4 !important;
  box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.1);
}
.job-box.job-primary .btn-soft:hover, .job-box.job-primary .btn-soft:focus, .job-box.job-primary .btn-soft:active, .job-box.job-primary .btn-soft.active, .job-box.job-primary .btn-soft.focus {
  background-color: #2f55d4 !important;
  border-color: #2f55d4 !important;
  color: #ffffff !important;
}

.job-box.job-secondary .position a:hover {
  color: #ced4da !important;
}
.job-box.job-secondary .company-name:hover {
  color: #ced4da !important;
}
.job-box.job-secondary .title:hover,
.job-box.job-secondary .readmore:hover {
  color: #ced4da !important;
}
.job-box.job-secondary .icons {
  color: #ced4da !important;
}
.job-box.job-secondary.candidate-list .name:hover, .job-box.job-secondary.company-list .name:hover {
  color: #ced4da !important;
}
.job-box.job-secondary .bg {
  background-color: #ced4da !important;
}
.job-box.job-secondary .bg-soft {
  background-color: rgba(206, 212, 218, 0.1) !important;
  border: 1px solid rgba(206, 212, 218, 0.1) !important;
  color: #ced4da !important;
}
.job-box.job-secondary .btn-soft {
  background-color: rgba(206, 212, 218, 0.05) !important;
  border: 1px solid rgba(206, 212, 218, 0.05) !important;
  color: #ced4da !important;
  box-shadow: 0 3px 5px 0 rgba(206, 212, 218, 0.1);
}
.job-box.job-secondary .btn-soft:hover, .job-box.job-secondary .btn-soft:focus, .job-box.job-secondary .btn-soft:active, .job-box.job-secondary .btn-soft.active, .job-box.job-secondary .btn-soft.focus {
  background-color: #ced4da !important;
  border-color: #ced4da !important;
  color: #ffffff !important;
}

.job-box.job-success .position a:hover {
  color: #2eca8b !important;
}
.job-box.job-success .company-name:hover {
  color: #2eca8b !important;
}
.job-box.job-success .title:hover,
.job-box.job-success .readmore:hover {
  color: #2eca8b !important;
}
.job-box.job-success .icons {
  color: #2eca8b !important;
}
.job-box.job-success.candidate-list .name:hover, .job-box.job-success.company-list .name:hover {
  color: #2eca8b !important;
}
.job-box.job-success .bg {
  background-color: #2eca8b !important;
}
.job-box.job-success .bg-soft {
  background-color: rgba(46, 202, 139, 0.1) !important;
  border: 1px solid rgba(46, 202, 139, 0.1) !important;
  color: #2eca8b !important;
}
.job-box.job-success .btn-soft {
  background-color: rgba(46, 202, 139, 0.05) !important;
  border: 1px solid rgba(46, 202, 139, 0.05) !important;
  color: #2eca8b !important;
  box-shadow: 0 3px 5px 0 rgba(46, 202, 139, 0.1);
}
.job-box.job-success .btn-soft:hover, .job-box.job-success .btn-soft:focus, .job-box.job-success .btn-soft:active, .job-box.job-success .btn-soft.active, .job-box.job-success .btn-soft.focus {
  background-color: #2eca8b !important;
  border-color: #2eca8b !important;
  color: #ffffff !important;
}

.job-box.job-info .position a:hover {
  color: #17a2b8 !important;
}
.job-box.job-info .company-name:hover {
  color: #17a2b8 !important;
}
.job-box.job-info .title:hover,
.job-box.job-info .readmore:hover {
  color: #17a2b8 !important;
}
.job-box.job-info .icons {
  color: #17a2b8 !important;
}
.job-box.job-info.candidate-list .name:hover, .job-box.job-info.company-list .name:hover {
  color: #17a2b8 !important;
}
.job-box.job-info .bg {
  background-color: #17a2b8 !important;
}
.job-box.job-info .bg-soft {
  background-color: rgba(23, 162, 184, 0.1) !important;
  border: 1px solid rgba(23, 162, 184, 0.1) !important;
  color: #17a2b8 !important;
}
.job-box.job-info .btn-soft {
  background-color: rgba(23, 162, 184, 0.05) !important;
  border: 1px solid rgba(23, 162, 184, 0.05) !important;
  color: #17a2b8 !important;
  box-shadow: 0 3px 5px 0 rgba(23, 162, 184, 0.1);
}
.job-box.job-info .btn-soft:hover, .job-box.job-info .btn-soft:focus, .job-box.job-info .btn-soft:active, .job-box.job-info .btn-soft.active, .job-box.job-info .btn-soft.focus {
  background-color: #17a2b8 !important;
  border-color: #17a2b8 !important;
  color: #ffffff !important;
}

.job-box.job-warning .position a:hover {
  color: #f17425 !important;
}
.job-box.job-warning .company-name:hover {
  color: #f17425 !important;
}
.job-box.job-warning .title:hover,
.job-box.job-warning .readmore:hover {
  color: #f17425 !important;
}
.job-box.job-warning .icons {
  color: #f17425 !important;
}
.job-box.job-warning.candidate-list .name:hover, .job-box.job-warning.company-list .name:hover {
  color: #f17425 !important;
}
.job-box.job-warning .bg {
  background-color: #f17425 !important;
}
.job-box.job-warning .bg-soft {
  background-color: rgba(241, 116, 37, 0.1) !important;
  border: 1px solid rgba(241, 116, 37, 0.1) !important;
  color: #f17425 !important;
}
.job-box.job-warning .btn-soft {
  background-color: rgba(241, 116, 37, 0.05) !important;
  border: 1px solid rgba(241, 116, 37, 0.05) !important;
  color: #f17425 !important;
  box-shadow: 0 3px 5px 0 rgba(241, 116, 37, 0.1);
}
.job-box.job-warning .btn-soft:hover, .job-box.job-warning .btn-soft:focus, .job-box.job-warning .btn-soft:active, .job-box.job-warning .btn-soft.active, .job-box.job-warning .btn-soft.focus {
  background-color: #f17425 !important;
  border-color: #f17425 !important;
  color: #ffffff !important;
}

.job-box.job-danger .position a:hover {
  color: #e43f52 !important;
}
.job-box.job-danger .company-name:hover {
  color: #e43f52 !important;
}
.job-box.job-danger .title:hover,
.job-box.job-danger .readmore:hover {
  color: #e43f52 !important;
}
.job-box.job-danger .icons {
  color: #e43f52 !important;
}
.job-box.job-danger.candidate-list .name:hover, .job-box.job-danger.company-list .name:hover {
  color: #e43f52 !important;
}
.job-box.job-danger .bg {
  background-color: #e43f52 !important;
}
.job-box.job-danger .bg-soft {
  background-color: rgba(228, 63, 82, 0.1) !important;
  border: 1px solid rgba(228, 63, 82, 0.1) !important;
  color: #e43f52 !important;
}
.job-box.job-danger .btn-soft {
  background-color: rgba(228, 63, 82, 0.05) !important;
  border: 1px solid rgba(228, 63, 82, 0.05) !important;
  color: #e43f52 !important;
  box-shadow: 0 3px 5px 0 rgba(228, 63, 82, 0.1);
}
.job-box.job-danger .btn-soft:hover, .job-box.job-danger .btn-soft:focus, .job-box.job-danger .btn-soft:active, .job-box.job-danger .btn-soft.active, .job-box.job-danger .btn-soft.focus {
  background-color: #e43f52 !important;
  border-color: #e43f52 !important;
  color: #ffffff !important;
}

.job-box.job-light .position a:hover {
  color: #1f2d3d !important;
}
.job-box.job-light .company-name:hover {
  color: #1f2d3d !important;
}
.job-box.job-light .title:hover,
.job-box.job-light .readmore:hover {
  color: #1f2d3d !important;
}
.job-box.job-light .icons {
  color: #1f2d3d !important;
}
.job-box.job-light.candidate-list .name:hover, .job-box.job-light.company-list .name:hover {
  color: #1f2d3d !important;
}
.job-box.job-light .bg {
  background-color: #1f2d3d !important;
}
.job-box.job-light .bg-soft {
  background-color: rgba(31, 45, 61, 0.1) !important;
  border: 1px solid rgba(31, 45, 61, 0.1) !important;
  color: #1f2d3d !important;
}
.job-box.job-light .btn-soft {
  background-color: rgba(31, 45, 61, 0.05) !important;
  border: 1px solid rgba(31, 45, 61, 0.05) !important;
  color: #1f2d3d !important;
  box-shadow: 0 3px 5px 0 rgba(31, 45, 61, 0.1);
}
.job-box.job-light .btn-soft:hover, .job-box.job-light .btn-soft:focus, .job-box.job-light .btn-soft:active, .job-box.job-light .btn-soft.active, .job-box.job-light .btn-soft.focus {
  background-color: #1f2d3d !important;
  border-color: #1f2d3d !important;
  color: #ffffff !important;
}

.job-box.job-dark .position a:hover {
  color: #f8f9fa !important;
}
.job-box.job-dark .company-name:hover {
  color: #f8f9fa !important;
}
.job-box.job-dark .title:hover,
.job-box.job-dark .readmore:hover {
  color: #f8f9fa !important;
}
.job-box.job-dark .icons {
  color: #f8f9fa !important;
}
.job-box.job-dark.candidate-list .name:hover, .job-box.job-dark.company-list .name:hover {
  color: #f8f9fa !important;
}
.job-box.job-dark .bg {
  background-color: #f8f9fa !important;
}
.job-box.job-dark .bg-soft {
  background-color: rgba(248, 249, 250, 0.1) !important;
  border: 1px solid rgba(248, 249, 250, 0.1) !important;
  color: #f8f9fa !important;
}
.job-box.job-dark .btn-soft {
  background-color: rgba(248, 249, 250, 0.05) !important;
  border: 1px solid rgba(248, 249, 250, 0.05) !important;
  color: #f8f9fa !important;
  box-shadow: 0 3px 5px 0 rgba(248, 249, 250, 0.1);
}
.job-box.job-dark .btn-soft:hover, .job-box.job-dark .btn-soft:focus, .job-box.job-dark .btn-soft:active, .job-box.job-dark .btn-soft.active, .job-box.job-dark .btn-soft.focus {
  background-color: #f8f9fa !important;
  border-color: #f8f9fa !important;
  color: #ffffff !important;
}

.job-box.job-footer .position a:hover {
  color: #212f40 !important;
}
.job-box.job-footer .company-name:hover {
  color: #212f40 !important;
}
.job-box.job-footer .title:hover,
.job-box.job-footer .readmore:hover {
  color: #212f40 !important;
}
.job-box.job-footer .icons {
  color: #212f40 !important;
}
.job-box.job-footer.candidate-list .name:hover, .job-box.job-footer.company-list .name:hover {
  color: #212f40 !important;
}
.job-box.job-footer .bg {
  background-color: #212f40 !important;
}
.job-box.job-footer .bg-soft {
  background-color: rgba(33, 47, 64, 0.1) !important;
  border: 1px solid rgba(33, 47, 64, 0.1) !important;
  color: #212f40 !important;
}
.job-box.job-footer .btn-soft {
  background-color: rgba(33, 47, 64, 0.05) !important;
  border: 1px solid rgba(33, 47, 64, 0.05) !important;
  color: #212f40 !important;
  box-shadow: 0 3px 5px 0 rgba(33, 47, 64, 0.1);
}
.job-box.job-footer .btn-soft:hover, .job-box.job-footer .btn-soft:focus, .job-box.job-footer .btn-soft:active, .job-box.job-footer .btn-soft.active, .job-box.job-footer .btn-soft.focus {
  background-color: #212f40 !important;
  border-color: #212f40 !important;
  color: #ffffff !important;
}

.job-box.job-muted .position a:hover {
  color: #9bacc4 !important;
}
.job-box.job-muted .company-name:hover {
  color: #9bacc4 !important;
}
.job-box.job-muted .title:hover,
.job-box.job-muted .readmore:hover {
  color: #9bacc4 !important;
}
.job-box.job-muted .icons {
  color: #9bacc4 !important;
}
.job-box.job-muted.candidate-list .name:hover, .job-box.job-muted.company-list .name:hover {
  color: #9bacc4 !important;
}
.job-box.job-muted .bg {
  background-color: #9bacc4 !important;
}
.job-box.job-muted .bg-soft {
  background-color: rgba(155, 172, 196, 0.1) !important;
  border: 1px solid rgba(155, 172, 196, 0.1) !important;
  color: #9bacc4 !important;
}
.job-box.job-muted .btn-soft {
  background-color: rgba(155, 172, 196, 0.05) !important;
  border: 1px solid rgba(155, 172, 196, 0.05) !important;
  color: #9bacc4 !important;
  box-shadow: 0 3px 5px 0 rgba(155, 172, 196, 0.1);
}
.job-box.job-muted .btn-soft:hover, .job-box.job-muted .btn-soft:focus, .job-box.job-muted .btn-soft:active, .job-box.job-muted .btn-soft.active, .job-box.job-muted .btn-soft.focus {
  background-color: #9bacc4 !important;
  border-color: #9bacc4 !important;
  color: #ffffff !important;
}

.job-box {
  transition: all 0.5s ease;
}
.job-box .job-overlay {
  opacity: 0.6;
}
.job-box .head {
  position: absolute;
  top: 15px;
  right: 15px;
}
.job-box .position {
  position: absolute;
  top: 15px;
  left: 15px;
}
.job-box .position a {
  transition: all 0.5s ease;
}
.job-box .firm-logo {
  position: absolute;
  left: 0;
  right: 0;
  height: 60px;
  width: 60px;
  line-height: 60px;
  margin: 0 auto;
  top: -30px;
}
.job-box .company-name {
  transition: all 0.5s ease;
}
.job-box.candidate-list .like {
  -webkit-text-stroke: 1px #9bacc4;
  -webkit-text-fill-color: transparent;
}
.job-box.candidate-list .like:hover, .job-box.candidate-list .like:focus {
  -webkit-text-stroke: #e43f52;
  -webkit-text-fill-color: #e43f52;
}
.job-box.candidate-list, .job-box.company-list {
  transition: all 0.5s ease;
}
.job-box.candidate-list:hover, .job-box.company-list:hover {
  box-shadow: 0 5px 13px rgba(173, 181, 189, 0.2) !important;
}
.job-box:hover {
  transform: translateY(-5px);
}

@media (min-width: 1024px) {
  .job-profile {
    top: -378px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .job-profile {
    top: -338px;
  }
}
@media (max-width: 767px) {
  .company-logo {
    float: none !important;
    text-align: left !important;
  }
}
.categories.category-primary .title:hover {
  color: #2f55d4 !important;
}

.categories.category-secondary .title:hover {
  color: #ced4da !important;
}

.categories.category-success .title:hover {
  color: #2eca8b !important;
}

.categories.category-info .title:hover {
  color: #17a2b8 !important;
}

.categories.category-warning .title:hover {
  color: #f17425 !important;
}

.categories.category-danger .title:hover {
  color: #e43f52 !important;
}

.categories.category-light .title:hover {
  color: #1f2d3d !important;
}

.categories.category-dark .title:hover {
  color: #f8f9fa !important;
}

.categories.category-footer .title:hover {
  color: #212f40 !important;
}

.categories.category-muted .title:hover {
  color: #9bacc4 !important;
}

.categories {
  transition: all 0.5s ease;
}
.categories:hover {
  transform: translateY(-10px);
  box-shadow: 0 0 3px rgba(173, 181, 189, 0.15);
}

/*********************************/
/*         Contact us            */
/*===============================*/
.form-icon .icons {
  position: absolute;
  top: 13px;
  left: 18px;
}

.map {
  line-height: 0;
}
.map iframe {
  width: 100%;
  height: 400px;
}

.error {
  margin: 8px 0px;
  display: none;
  color: #e43f52;
}

#ajaxsuccess {
  font-size: 16px;
  width: 100%;
  display: none;
  clear: both;
  margin: 8px 0px;
}

.error_message,
#success_page {
  padding: 10px;
  margin-bottom: 20px;
  text-align: center;
  border-radius: 5px;
}

.error_message {
  background-color: rgba(240, 115, 90, 0.1) !important;
  border: 2px solid rgba(240, 115, 90, 0.1) !important;
  color: #f0735a !important;
  font-size: 14px;
}

.contact-loader {
  display: none;
}

#success_page {
  background-color: rgba(83, 199, 151, 0.1) !important;
  border: 2px solid rgba(83, 199, 151, 0.1) !important;
  color: #53c797 !important;
}
#success_page p {
  margin-bottom: 0 !important;
}

.qs-datepicker-container {
  background-color: #1c2836;
  border-color: #2a3c51;
  color: #fff;
  box-shadow: 0 0 3px rgba(173, 181, 189, 0.15);
}
.qs-datepicker-container .qs-controls {
  background-color: #2a3c51;
  color: #fff;
}
.qs-datepicker-container .qs-controls .qs-month-year {
  font-weight: 600;
  font-size: 15px;
}
.qs-datepicker-container .qs-controls .qs-month-year:hover {
  border-bottom: 1px solid #2a3c51;
}
.qs-datepicker-container .qs-squares .qs-square {
  font-size: 15px;
  height: 34px;
  width: 34px;
}
.qs-datepicker-container .qs-squares .qs-square:not(.qs-empty):not(.qs-disabled):not(.qs-day):not(.qs-active):hover, .qs-datepicker-container .qs-squares .qs-square.qs-current {
  background: #2f55d4;
  color: #ffffff;
  border-radius: 30px;
  font-weight: 500;
  text-decoration: none;
  border-radius: 30px;
}
.qs-datepicker-container .qs-squares .qs-day {
  font-weight: 500;
  color: #9bacc4;
}

@media (min-width: 768px) {
  .map.map-height-two iframe {
    height: 551px;
  }
}
/*********************************/
/* Countdown(Comingsoon, Maintenance) */
/*===============================*/
.coming-soon, .maintenance, .error-page {
  font-size: 40px;
  letter-spacing: 1px;
}
@media (max-width: 767px) {
  .coming-soon, .maintenance, .error-page {
    font-size: 32px;
  }
}

#countdown .count-down, #eventdown .count-down, #token-sale .count-down {
  display: inline-block;
  margin: 30px 10px 10px;
  color: #ffffff;
  text-align: center;
}
#countdown .count-down .count-number, #eventdown .count-down .count-number, #token-sale .count-down .count-number {
  font-size: 40px;
  line-height: 95px;
}
#countdown .count-down .count-head, #eventdown .count-down .count-head, #token-sale .count-down .count-head {
  display: block;
  position: relative;
  transform: translateY(-20px);
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1px;
}

#countdown .count-down .count-number {
  font-size: 40px;
  line-height: 95px;
  border: 4px solid #2a3c51;
  height: 130px;
  width: 130px;
  border-radius: 50%;
}

#token-sale .count-down {
  border: 0;
  color: #f8f9fa !important;
}
#token-sale .count-down .count-number {
  line-height: 80px;
}
@media (max-width: 767px) {
  #token-sale .count-down .count-number {
    font-size: 30px;
  }
}
#token-sale .count-down .count-head {
  margin-bottom: 0 !important;
  transform: translateY(-15px);
}
@media (max-width: 767px) {
  #token-sale .count-down .count-head {
    font-size: 10px;
  }
}

#eventdown .count-down {
  margin: 10px !important;
  border: 0;
}
@media (max-width: 767px) {
  #eventdown .count-down {
    width: 60px;
  }
}
#eventdown .count-down .count-number {
  line-height: 80px;
}
@media (max-width: 767px) {
  #eventdown .count-down .count-number {
    font-size: 30px;
  }
}
#eventdown .count-down .count-head {
  transform: translateY(-15px);
}
@media (max-width: 767px) {
  #eventdown .count-down .count-head {
    font-size: 10px;
  }
}

#maintenance {
  color: #ffffff;
  font-size: 56px;
  letter-spacing: 1px;
}
#maintenance .indicator {
  font-size: 18px;
}

/*********************************/
/*         CTA                   */
/*===============================*/
.bg-cta {
  padding: 150px 0;
}

.play-btn {
  height: 78px;
  width: 78px;
  font-size: 30px;
  line-height: 76px;
  border-radius: 50%;
  display: inline-block;
  color: #ffffff;
  border: 1px solid #fff;
}

.play-icon {
  position: absolute;
  bottom: 50%;
  transform: translateY(50%);
  right: 0;
  left: 0;
  text-align: center;
}
.play-icon i {
  height: 75px;
  -webkit-text-stroke: 2px #2f55d4;
  -webkit-text-fill-color: transparent;
  width: 75px;
  font-size: 25px;
  line-height: 75px;
  display: inline-block;
  background-color: #161c2d;
}
.play-icon i:hover {
  -webkit-text-stroke: 2px #2f55d4;
  -webkit-text-fill-color: #2f55d4;
}

@media (max-width: 767px) {
  iframe {
    width: auto !important;
  }
}
/*********************************/
/*     Features & Services       */
/*===============================*/
.features.feature-primary .icon {
  background: rgba(47, 85, 212, 0.1);
}
.features.feature-primary .image:before {
  background: linear-gradient(45deg, transparent, rgba(47, 85, 212, 0.1));
}
.features.feature-primary .read-more,
.features.feature-primary .color {
  color: #2f55d4 !important;
}
.features.feature-primary.feature-clean .icons {
  color: #2f55d4 !important;
}
.features.feature-primary.feature-clean .icons i {
  background-color: rgba(47, 85, 212, 0.1);
}
.features.feature-primary.feature-clean .title:hover {
  color: #2f55d4 !important;
}
.features.feature-primary.feature-full-bg .icon-color {
  color: #2f55d4 !important;
}
.features.feature-primary.feature-full-bg:hover {
  background-color: #2f55d4 !important;
}
.features.feature-primary.key-feature .icon {
  background: linear-gradient(45deg, transparent, rgba(47, 85, 212, 0.15));
  color: #2f55d4;
}
.features.feature-primary.course-feature .full-img {
  color: #2f55d4 !important;
}
.features.feature-primary.explore-feature:hover .icons,
.features.feature-primary.explore-feature:hover .title {
  color: #2f55d4 !important;
}
.features.feature-primary .btn-soft {
  background-color: rgba(47, 85, 212, 0.05) !important;
  border: 1px solid rgba(47, 85, 212, 0.05) !important;
  color: #2f55d4 !important;
  box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.1);
}
.features.feature-primary .btn-soft:hover, .features.feature-primary .btn-soft:focus, .features.feature-primary .btn-soft:active, .features.feature-primary .btn-soft.active, .features.feature-primary .btn-soft.focus {
  background-color: #2f55d4 !important;
  border-color: #2f55d4 !important;
  color: #ffffff !important;
}
.features.feature-primary:hover .image:before {
  background: rgba(47, 85, 212, 0.1);
}

.features.feature-secondary .icon {
  background: rgba(206, 212, 218, 0.1);
}
.features.feature-secondary .image:before {
  background: linear-gradient(45deg, transparent, rgba(206, 212, 218, 0.1));
}
.features.feature-secondary .read-more,
.features.feature-secondary .color {
  color: #ced4da !important;
}
.features.feature-secondary.feature-clean .icons {
  color: #ced4da !important;
}
.features.feature-secondary.feature-clean .icons i {
  background-color: rgba(206, 212, 218, 0.1);
}
.features.feature-secondary.feature-clean .title:hover {
  color: #ced4da !important;
}
.features.feature-secondary.feature-full-bg .icon-color {
  color: #ced4da !important;
}
.features.feature-secondary.feature-full-bg:hover {
  background-color: #ced4da !important;
}
.features.feature-secondary.key-feature .icon {
  background: linear-gradient(45deg, transparent, rgba(206, 212, 218, 0.15));
  color: #ced4da;
}
.features.feature-secondary.course-feature .full-img {
  color: #ced4da !important;
}
.features.feature-secondary.explore-feature:hover .icons,
.features.feature-secondary.explore-feature:hover .title {
  color: #ced4da !important;
}
.features.feature-secondary .btn-soft {
  background-color: rgba(206, 212, 218, 0.05) !important;
  border: 1px solid rgba(206, 212, 218, 0.05) !important;
  color: #ced4da !important;
  box-shadow: 0 3px 5px 0 rgba(206, 212, 218, 0.1);
}
.features.feature-secondary .btn-soft:hover, .features.feature-secondary .btn-soft:focus, .features.feature-secondary .btn-soft:active, .features.feature-secondary .btn-soft.active, .features.feature-secondary .btn-soft.focus {
  background-color: #ced4da !important;
  border-color: #ced4da !important;
  color: #ffffff !important;
}
.features.feature-secondary:hover .image:before {
  background: rgba(206, 212, 218, 0.1);
}

.features.feature-success .icon {
  background: rgba(46, 202, 139, 0.1);
}
.features.feature-success .image:before {
  background: linear-gradient(45deg, transparent, rgba(46, 202, 139, 0.1));
}
.features.feature-success .read-more,
.features.feature-success .color {
  color: #2eca8b !important;
}
.features.feature-success.feature-clean .icons {
  color: #2eca8b !important;
}
.features.feature-success.feature-clean .icons i {
  background-color: rgba(46, 202, 139, 0.1);
}
.features.feature-success.feature-clean .title:hover {
  color: #2eca8b !important;
}
.features.feature-success.feature-full-bg .icon-color {
  color: #2eca8b !important;
}
.features.feature-success.feature-full-bg:hover {
  background-color: #2eca8b !important;
}
.features.feature-success.key-feature .icon {
  background: linear-gradient(45deg, transparent, rgba(46, 202, 139, 0.15));
  color: #2eca8b;
}
.features.feature-success.course-feature .full-img {
  color: #2eca8b !important;
}
.features.feature-success.explore-feature:hover .icons,
.features.feature-success.explore-feature:hover .title {
  color: #2eca8b !important;
}
.features.feature-success .btn-soft {
  background-color: rgba(46, 202, 139, 0.05) !important;
  border: 1px solid rgba(46, 202, 139, 0.05) !important;
  color: #2eca8b !important;
  box-shadow: 0 3px 5px 0 rgba(46, 202, 139, 0.1);
}
.features.feature-success .btn-soft:hover, .features.feature-success .btn-soft:focus, .features.feature-success .btn-soft:active, .features.feature-success .btn-soft.active, .features.feature-success .btn-soft.focus {
  background-color: #2eca8b !important;
  border-color: #2eca8b !important;
  color: #ffffff !important;
}
.features.feature-success:hover .image:before {
  background: rgba(46, 202, 139, 0.1);
}

.features.feature-info .icon {
  background: rgba(23, 162, 184, 0.1);
}
.features.feature-info .image:before {
  background: linear-gradient(45deg, transparent, rgba(23, 162, 184, 0.1));
}
.features.feature-info .read-more,
.features.feature-info .color {
  color: #17a2b8 !important;
}
.features.feature-info.feature-clean .icons {
  color: #17a2b8 !important;
}
.features.feature-info.feature-clean .icons i {
  background-color: rgba(23, 162, 184, 0.1);
}
.features.feature-info.feature-clean .title:hover {
  color: #17a2b8 !important;
}
.features.feature-info.feature-full-bg .icon-color {
  color: #17a2b8 !important;
}
.features.feature-info.feature-full-bg:hover {
  background-color: #17a2b8 !important;
}
.features.feature-info.key-feature .icon {
  background: linear-gradient(45deg, transparent, rgba(23, 162, 184, 0.15));
  color: #17a2b8;
}
.features.feature-info.course-feature .full-img {
  color: #17a2b8 !important;
}
.features.feature-info.explore-feature:hover .icons,
.features.feature-info.explore-feature:hover .title {
  color: #17a2b8 !important;
}
.features.feature-info .btn-soft {
  background-color: rgba(23, 162, 184, 0.05) !important;
  border: 1px solid rgba(23, 162, 184, 0.05) !important;
  color: #17a2b8 !important;
  box-shadow: 0 3px 5px 0 rgba(23, 162, 184, 0.1);
}
.features.feature-info .btn-soft:hover, .features.feature-info .btn-soft:focus, .features.feature-info .btn-soft:active, .features.feature-info .btn-soft.active, .features.feature-info .btn-soft.focus {
  background-color: #17a2b8 !important;
  border-color: #17a2b8 !important;
  color: #ffffff !important;
}
.features.feature-info:hover .image:before {
  background: rgba(23, 162, 184, 0.1);
}

.features.feature-warning .icon {
  background: rgba(241, 116, 37, 0.1);
}
.features.feature-warning .image:before {
  background: linear-gradient(45deg, transparent, rgba(241, 116, 37, 0.1));
}
.features.feature-warning .read-more,
.features.feature-warning .color {
  color: #f17425 !important;
}
.features.feature-warning.feature-clean .icons {
  color: #f17425 !important;
}
.features.feature-warning.feature-clean .icons i {
  background-color: rgba(241, 116, 37, 0.1);
}
.features.feature-warning.feature-clean .title:hover {
  color: #f17425 !important;
}
.features.feature-warning.feature-full-bg .icon-color {
  color: #f17425 !important;
}
.features.feature-warning.feature-full-bg:hover {
  background-color: #f17425 !important;
}
.features.feature-warning.key-feature .icon {
  background: linear-gradient(45deg, transparent, rgba(241, 116, 37, 0.15));
  color: #f17425;
}
.features.feature-warning.course-feature .full-img {
  color: #f17425 !important;
}
.features.feature-warning.explore-feature:hover .icons,
.features.feature-warning.explore-feature:hover .title {
  color: #f17425 !important;
}
.features.feature-warning .btn-soft {
  background-color: rgba(241, 116, 37, 0.05) !important;
  border: 1px solid rgba(241, 116, 37, 0.05) !important;
  color: #f17425 !important;
  box-shadow: 0 3px 5px 0 rgba(241, 116, 37, 0.1);
}
.features.feature-warning .btn-soft:hover, .features.feature-warning .btn-soft:focus, .features.feature-warning .btn-soft:active, .features.feature-warning .btn-soft.active, .features.feature-warning .btn-soft.focus {
  background-color: #f17425 !important;
  border-color: #f17425 !important;
  color: #ffffff !important;
}
.features.feature-warning:hover .image:before {
  background: rgba(241, 116, 37, 0.1);
}

.features.feature-danger .icon {
  background: rgba(228, 63, 82, 0.1);
}
.features.feature-danger .image:before {
  background: linear-gradient(45deg, transparent, rgba(228, 63, 82, 0.1));
}
.features.feature-danger .read-more,
.features.feature-danger .color {
  color: #e43f52 !important;
}
.features.feature-danger.feature-clean .icons {
  color: #e43f52 !important;
}
.features.feature-danger.feature-clean .icons i {
  background-color: rgba(228, 63, 82, 0.1);
}
.features.feature-danger.feature-clean .title:hover {
  color: #e43f52 !important;
}
.features.feature-danger.feature-full-bg .icon-color {
  color: #e43f52 !important;
}
.features.feature-danger.feature-full-bg:hover {
  background-color: #e43f52 !important;
}
.features.feature-danger.key-feature .icon {
  background: linear-gradient(45deg, transparent, rgba(228, 63, 82, 0.15));
  color: #e43f52;
}
.features.feature-danger.course-feature .full-img {
  color: #e43f52 !important;
}
.features.feature-danger.explore-feature:hover .icons,
.features.feature-danger.explore-feature:hover .title {
  color: #e43f52 !important;
}
.features.feature-danger .btn-soft {
  background-color: rgba(228, 63, 82, 0.05) !important;
  border: 1px solid rgba(228, 63, 82, 0.05) !important;
  color: #e43f52 !important;
  box-shadow: 0 3px 5px 0 rgba(228, 63, 82, 0.1);
}
.features.feature-danger .btn-soft:hover, .features.feature-danger .btn-soft:focus, .features.feature-danger .btn-soft:active, .features.feature-danger .btn-soft.active, .features.feature-danger .btn-soft.focus {
  background-color: #e43f52 !important;
  border-color: #e43f52 !important;
  color: #ffffff !important;
}
.features.feature-danger:hover .image:before {
  background: rgba(228, 63, 82, 0.1);
}

.features.feature-light .icon {
  background: rgba(31, 45, 61, 0.1);
}
.features.feature-light .image:before {
  background: linear-gradient(45deg, transparent, rgba(31, 45, 61, 0.1));
}
.features.feature-light .read-more,
.features.feature-light .color {
  color: #1f2d3d !important;
}
.features.feature-light.feature-clean .icons {
  color: #1f2d3d !important;
}
.features.feature-light.feature-clean .icons i {
  background-color: rgba(31, 45, 61, 0.1);
}
.features.feature-light.feature-clean .title:hover {
  color: #1f2d3d !important;
}
.features.feature-light.feature-full-bg .icon-color {
  color: #1f2d3d !important;
}
.features.feature-light.feature-full-bg:hover {
  background-color: #1f2d3d !important;
}
.features.feature-light.key-feature .icon {
  background: linear-gradient(45deg, transparent, rgba(31, 45, 61, 0.15));
  color: #1f2d3d;
}
.features.feature-light.course-feature .full-img {
  color: #1f2d3d !important;
}
.features.feature-light.explore-feature:hover .icons,
.features.feature-light.explore-feature:hover .title {
  color: #1f2d3d !important;
}
.features.feature-light .btn-soft {
  background-color: rgba(31, 45, 61, 0.05) !important;
  border: 1px solid rgba(31, 45, 61, 0.05) !important;
  color: #1f2d3d !important;
  box-shadow: 0 3px 5px 0 rgba(31, 45, 61, 0.1);
}
.features.feature-light .btn-soft:hover, .features.feature-light .btn-soft:focus, .features.feature-light .btn-soft:active, .features.feature-light .btn-soft.active, .features.feature-light .btn-soft.focus {
  background-color: #1f2d3d !important;
  border-color: #1f2d3d !important;
  color: #ffffff !important;
}
.features.feature-light:hover .image:before {
  background: rgba(31, 45, 61, 0.1);
}

.features.feature-dark .icon {
  background: rgba(248, 249, 250, 0.1);
}
.features.feature-dark .image:before {
  background: linear-gradient(45deg, transparent, rgba(248, 249, 250, 0.1));
}
.features.feature-dark .read-more,
.features.feature-dark .color {
  color: #f8f9fa !important;
}
.features.feature-dark.feature-clean .icons {
  color: #f8f9fa !important;
}
.features.feature-dark.feature-clean .icons i {
  background-color: rgba(248, 249, 250, 0.1);
}
.features.feature-dark.feature-clean .title:hover {
  color: #f8f9fa !important;
}
.features.feature-dark.feature-full-bg .icon-color {
  color: #f8f9fa !important;
}
.features.feature-dark.feature-full-bg:hover {
  background-color: #f8f9fa !important;
}
.features.feature-dark.key-feature .icon {
  background: linear-gradient(45deg, transparent, rgba(248, 249, 250, 0.15));
  color: #f8f9fa;
}
.features.feature-dark.course-feature .full-img {
  color: #f8f9fa !important;
}
.features.feature-dark.explore-feature:hover .icons,
.features.feature-dark.explore-feature:hover .title {
  color: #f8f9fa !important;
}
.features.feature-dark .btn-soft {
  background-color: rgba(248, 249, 250, 0.05) !important;
  border: 1px solid rgba(248, 249, 250, 0.05) !important;
  color: #f8f9fa !important;
  box-shadow: 0 3px 5px 0 rgba(248, 249, 250, 0.1);
}
.features.feature-dark .btn-soft:hover, .features.feature-dark .btn-soft:focus, .features.feature-dark .btn-soft:active, .features.feature-dark .btn-soft.active, .features.feature-dark .btn-soft.focus {
  background-color: #f8f9fa !important;
  border-color: #f8f9fa !important;
  color: #ffffff !important;
}
.features.feature-dark:hover .image:before {
  background: rgba(248, 249, 250, 0.1);
}

.features.feature-footer .icon {
  background: rgba(33, 47, 64, 0.1);
}
.features.feature-footer .image:before {
  background: linear-gradient(45deg, transparent, rgba(33, 47, 64, 0.1));
}
.features.feature-footer .read-more,
.features.feature-footer .color {
  color: #212f40 !important;
}
.features.feature-footer.feature-clean .icons {
  color: #212f40 !important;
}
.features.feature-footer.feature-clean .icons i {
  background-color: rgba(33, 47, 64, 0.1);
}
.features.feature-footer.feature-clean .title:hover {
  color: #212f40 !important;
}
.features.feature-footer.feature-full-bg .icon-color {
  color: #212f40 !important;
}
.features.feature-footer.feature-full-bg:hover {
  background-color: #212f40 !important;
}
.features.feature-footer.key-feature .icon {
  background: linear-gradient(45deg, transparent, rgba(33, 47, 64, 0.15));
  color: #212f40;
}
.features.feature-footer.course-feature .full-img {
  color: #212f40 !important;
}
.features.feature-footer.explore-feature:hover .icons,
.features.feature-footer.explore-feature:hover .title {
  color: #212f40 !important;
}
.features.feature-footer .btn-soft {
  background-color: rgba(33, 47, 64, 0.05) !important;
  border: 1px solid rgba(33, 47, 64, 0.05) !important;
  color: #212f40 !important;
  box-shadow: 0 3px 5px 0 rgba(33, 47, 64, 0.1);
}
.features.feature-footer .btn-soft:hover, .features.feature-footer .btn-soft:focus, .features.feature-footer .btn-soft:active, .features.feature-footer .btn-soft.active, .features.feature-footer .btn-soft.focus {
  background-color: #212f40 !important;
  border-color: #212f40 !important;
  color: #ffffff !important;
}
.features.feature-footer:hover .image:before {
  background: rgba(33, 47, 64, 0.1);
}

.features.feature-muted .icon {
  background: rgba(155, 172, 196, 0.1);
}
.features.feature-muted .image:before {
  background: linear-gradient(45deg, transparent, rgba(155, 172, 196, 0.1));
}
.features.feature-muted .read-more,
.features.feature-muted .color {
  color: #9bacc4 !important;
}
.features.feature-muted.feature-clean .icons {
  color: #9bacc4 !important;
}
.features.feature-muted.feature-clean .icons i {
  background-color: rgba(155, 172, 196, 0.1);
}
.features.feature-muted.feature-clean .title:hover {
  color: #9bacc4 !important;
}
.features.feature-muted.feature-full-bg .icon-color {
  color: #9bacc4 !important;
}
.features.feature-muted.feature-full-bg:hover {
  background-color: #9bacc4 !important;
}
.features.feature-muted.key-feature .icon {
  background: linear-gradient(45deg, transparent, rgba(155, 172, 196, 0.15));
  color: #9bacc4;
}
.features.feature-muted.course-feature .full-img {
  color: #9bacc4 !important;
}
.features.feature-muted.explore-feature:hover .icons,
.features.feature-muted.explore-feature:hover .title {
  color: #9bacc4 !important;
}
.features.feature-muted .btn-soft {
  background-color: rgba(155, 172, 196, 0.05) !important;
  border: 1px solid rgba(155, 172, 196, 0.05) !important;
  color: #9bacc4 !important;
  box-shadow: 0 3px 5px 0 rgba(155, 172, 196, 0.1);
}
.features.feature-muted .btn-soft:hover, .features.feature-muted .btn-soft:focus, .features.feature-muted .btn-soft:active, .features.feature-muted .btn-soft.active, .features.feature-muted .btn-soft.focus {
  background-color: #9bacc4 !important;
  border-color: #9bacc4 !important;
  color: #ffffff !important;
}
.features.feature-muted:hover .image:before {
  background: rgba(155, 172, 196, 0.1);
}

.features .image:before {
  content: "";
  position: absolute;
  bottom: 5px;
  left: 5px;
  width: 64px;
  height: 64px;
  border-radius: 6px;
  transform: rotate(33.75deg);
}
.features.feature-clean {
  background-color: #1c2836;
}
.features.feature-clean .icons i {
  height: 65px;
  width: 65px;
  line-height: 65px;
}
.features .ride-image {
  transform: rotate(-45deg);
}
.features .ride-image img {
  border-radius: 100% 100% 100% 0;
}
.features.key-feature {
  transition: all 0.3s ease;
}
.features.key-feature:hover {
  transform: scale(1.05);
  box-shadow: 0 5px 13px rgba(173, 181, 189, 0.2) !important;
}
.features:hover .image:before {
  animation: spinner-border 5s linear infinite !important;
}
.features.feature-full-bg {
  transition: all 0.5s ease;
}
.features.feature-full-bg .big-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  opacity: 0.015;
  font-size: 180px;
}
.features.feature-full-bg:hover {
  box-shadow: 0 10px 25px rgba(173, 181, 189, 0.15);
  color: #ffffff !important;
}
.features.feature-full-bg:hover .icon-color i,
.features.feature-full-bg:hover .para {
  color: rgba(255, 255, 255, 0.5) !important;
}
.features.feature-full-bg:hover .content,
.features.feature-full-bg:hover .icon-color {
  z-index: 2;
}
.features.feature-full-bg:hover .big-icon {
  opacity: 0.05;
}
.features.feature-full-bg:hover .readmore {
  color: #ffffff !important;
}
.features.course-feature {
  transition: all 0.5s ease;
}
.features.course-feature .full-img {
  position: absolute;
  bottom: 50%;
  transform: translateY(50%);
  left: 0;
  right: 0;
  opacity: 0;
  margin: 0 auto;
  z-index: -2;
  transition: all 0.5s ease;
  font-size: 250px;
}
.features.course-feature:hover {
  transform: translateY(-10px);
}
.features.course-feature:hover .full-img {
  opacity: 0.05;
}
.features.explore-feature {
  transition: all 0.5s ease;
}
.features.explore-feature .icons {
  height: 80px;
  width: 80px;
  line-height: 80px;
  transition: all 0.5s ease;
}
.features.explore-feature:hover {
  box-shadow: 0 10px 25px rgba(173, 181, 189, 0.15) !important;
}

.features .title,
.key-feature .title {
  font-size: 18px !important;
}
.features .icon,
.key-feature .icon {
  height: 45px;
  min-width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.work-process {
  transition: all 0.5s ease;
}
.work-process .step, .work-process .step-icon {
  opacity: 0.06;
}
.work-process a {
  font-size: 15px;
}
.work-process.process-arrow:after {
  content: "";
  position: absolute;
  width: 60%;
  height: 40px;
  top: 30px;
  left: 80%;
  background: url("../images/process.png") center center no-repeat;
  z-index: 1;
}
@media (max-width: 767px) {
  .work-process.process-arrow:after {
    display: none;
  }
}
.work-process.process-arrow:hover {
  transform: none !important;
}
.work-process:hover {
  transform: translateY(-10px);
}
.work-process.d-none-arrow:hover {
  transform: none !important;
}

.features-absolute {
  position: relative;
  z-index: 2;
  transition: all 0.5s ease;
}

.features-absolute {
  margin: -200px 0 0px;
}
@media (max-width: 768px) {
  .features-absolute {
    margin: -140px 0 0;
  }
}
.features-absolute.blog-search {
  margin: -120px 0 0px;
}
@media (max-width: 768px) {
  .features-absolute.blog-search {
    margin: -90px 0 0px;
  }
}

.app-feature-shape-right:after,
.app-feature-shape-left:after {
  bottom: 0rem;
  width: 30rem;
  height: 40rem;
  border-radius: 10rem;
}

.app-feature-shape-right:after {
  left: 10rem;
  transform: rotate(150deg);
}
@media (max-width: 768px) {
  .app-feature-shape-right:after {
    left: 0;
  }
}

.app-feature-shape-left:after {
  right: 10rem;
  transform: rotate(210deg);
}
@media (max-width: 768px) {
  .app-feature-shape-left:after {
    right: 0;
  }
}

.app-subscribe {
  position: absolute;
  top: -200px;
}
@media (max-width: 767px) {
  .app-subscribe {
    position: relative;
    top: 0;
  }
}

.saas-feature-shape-left:after,
.saas-feature-shape-right:after {
  bottom: -3rem;
  width: 35rem;
  height: 35rem;
  border-radius: 15rem;
  transform: rotate(145deg);
}
@media (max-width: 767px) {
  .saas-feature-shape-left:after,
.saas-feature-shape-right:after {
    bottom: -1rem;
    width: 25rem;
    height: 25rem;
    border-radius: 10rem;
  }
}

.saas-feature-shape-left:after {
  left: -10rem;
}

.saas-feature-shape-right:after {
  right: -10rem;
}

.app-feature-shape-right:after,
.app-feature-shape-left:after,
.saas-feature-shape-left:after,
.saas-feature-shape-right:after,
.social-feature-left:after,
.social-feature-right:after {
  background: rgba(47, 85, 212, 0.1);
  box-shadow: 0 0 40px rgba(47, 85, 212, 0.1);
}

.app-feature-shape-right:after,
.app-feature-shape-left:after,
.saas-feature-shape-left:after,
.saas-feature-shape-right:after,
.social-feature-left:after,
.social-feature-right:after {
  content: "";
  position: absolute;
  z-index: -1;
}

.social-feature-left:after,
.social-feature-right:after {
  width: 450px;
  height: 520px;
  border-radius: 30px;
  bottom: -75px;
}
@media (max-width: 768px) {
  .social-feature-left:after,
.social-feature-right:after {
    width: 300px;
    height: 400px;
    bottom: -65px;
  }
}
@media (max-width: 575px) {
  .social-feature-left:after,
.social-feature-right:after {
    bottom: -35px;
  }
}

.social-feature-left:after {
  right: -200px;
}
@media (max-width: 575px) {
  .social-feature-left:after {
    right: 60px;
  }
}

.social-feature-right:after {
  left: -200px;
}
@media (max-width: 575px) {
  .social-feature-right:after {
    left: 60px;
  }
}

.construction-img {
  position: absolute;
  bottom: 0;
  right: 20px;
}

/*********************************/
/*          NFT                  */
/*===============================*/
.nft.nft-primary .title:hover,
.nft.nft-primary .read-more:hover {
  color: #2f55d4 !important;
}
.nft.nft-primary .link {
  color: #2f55d4 !important;
}
.nft.nft-primary .author .name:hover {
  color: #2f55d4 !important;
}
.nft.nft-primary .bg-soft {
  background-color: rgba(47, 85, 212, 0.1) !important;
  border: 1px solid rgba(47, 85, 212, 0.1) !important;
  color: #2f55d4 !important;
}
.nft.nft-primary .btn {
  background-color: #2f55d4 !important;
  border: 1px solid #2f55d4 !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.1);
}
.nft.nft-primary .btn:hover, .nft.nft-primary .btn:focus, .nft.nft-primary .btn:active, .nft.nft-primary .btn.active, .nft.nft-primary .btn.focus {
  background-color: #2443ac !important;
  border-color: #2443ac !important;
  color: #ffffff !important;
}

.nft.nft-secondary .title:hover,
.nft.nft-secondary .read-more:hover {
  color: #ced4da !important;
}
.nft.nft-secondary .link {
  color: #ced4da !important;
}
.nft.nft-secondary .author .name:hover {
  color: #ced4da !important;
}
.nft.nft-secondary .bg-soft {
  background-color: rgba(206, 212, 218, 0.1) !important;
  border: 1px solid rgba(206, 212, 218, 0.1) !important;
  color: #ced4da !important;
}
.nft.nft-secondary .btn {
  background-color: #ced4da !important;
  border: 1px solid #ced4da !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(206, 212, 218, 0.1);
}
.nft.nft-secondary .btn:hover, .nft.nft-secondary .btn:focus, .nft.nft-secondary .btn:active, .nft.nft-secondary .btn.active, .nft.nft-secondary .btn.focus {
  background-color: #b1bbc4 !important;
  border-color: #b1bbc4 !important;
  color: #ffffff !important;
}

.nft.nft-success .title:hover,
.nft.nft-success .read-more:hover {
  color: #2eca8b !important;
}
.nft.nft-success .link {
  color: #2eca8b !important;
}
.nft.nft-success .author .name:hover {
  color: #2eca8b !important;
}
.nft.nft-success .bg-soft {
  background-color: rgba(46, 202, 139, 0.1) !important;
  border: 1px solid rgba(46, 202, 139, 0.1) !important;
  color: #2eca8b !important;
}
.nft.nft-success .btn {
  background-color: #2eca8b !important;
  border: 1px solid #2eca8b !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(46, 202, 139, 0.1);
}
.nft.nft-success .btn:hover, .nft.nft-success .btn:focus, .nft.nft-success .btn:active, .nft.nft-success .btn.active, .nft.nft-success .btn.focus {
  background-color: #25a06e !important;
  border-color: #25a06e !important;
  color: #ffffff !important;
}

.nft.nft-info .title:hover,
.nft.nft-info .read-more:hover {
  color: #17a2b8 !important;
}
.nft.nft-info .link {
  color: #17a2b8 !important;
}
.nft.nft-info .author .name:hover {
  color: #17a2b8 !important;
}
.nft.nft-info .bg-soft {
  background-color: rgba(23, 162, 184, 0.1) !important;
  border: 1px solid rgba(23, 162, 184, 0.1) !important;
  color: #17a2b8 !important;
}
.nft.nft-info .btn {
  background-color: #17a2b8 !important;
  border: 1px solid #17a2b8 !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(23, 162, 184, 0.1);
}
.nft.nft-info .btn:hover, .nft.nft-info .btn:focus, .nft.nft-info .btn:active, .nft.nft-info .btn.active, .nft.nft-info .btn.focus {
  background-color: #117a8b !important;
  border-color: #117a8b !important;
  color: #ffffff !important;
}

.nft.nft-warning .title:hover,
.nft.nft-warning .read-more:hover {
  color: #f17425 !important;
}
.nft.nft-warning .link {
  color: #f17425 !important;
}
.nft.nft-warning .author .name:hover {
  color: #f17425 !important;
}
.nft.nft-warning .bg-soft {
  background-color: rgba(241, 116, 37, 0.1) !important;
  border: 1px solid rgba(241, 116, 37, 0.1) !important;
  color: #f17425 !important;
}
.nft.nft-warning .btn {
  background-color: #f17425 !important;
  border: 1px solid #f17425 !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(241, 116, 37, 0.1);
}
.nft.nft-warning .btn:hover, .nft.nft-warning .btn:focus, .nft.nft-warning .btn:active, .nft.nft-warning .btn.active, .nft.nft-warning .btn.focus {
  background-color: #d55b0e !important;
  border-color: #d55b0e !important;
  color: #ffffff !important;
}

.nft.nft-danger .title:hover,
.nft.nft-danger .read-more:hover {
  color: #e43f52 !important;
}
.nft.nft-danger .link {
  color: #e43f52 !important;
}
.nft.nft-danger .author .name:hover {
  color: #e43f52 !important;
}
.nft.nft-danger .bg-soft {
  background-color: rgba(228, 63, 82, 0.1) !important;
  border: 1px solid rgba(228, 63, 82, 0.1) !important;
  color: #e43f52 !important;
}
.nft.nft-danger .btn {
  background-color: #e43f52 !important;
  border: 1px solid #e43f52 !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(228, 63, 82, 0.1);
}
.nft.nft-danger .btn:hover, .nft.nft-danger .btn:focus, .nft.nft-danger .btn:active, .nft.nft-danger .btn.active, .nft.nft-danger .btn.focus {
  background-color: #d21e32 !important;
  border-color: #d21e32 !important;
  color: #ffffff !important;
}

.nft.nft-light .title:hover,
.nft.nft-light .read-more:hover {
  color: #1f2d3d !important;
}
.nft.nft-light .link {
  color: #1f2d3d !important;
}
.nft.nft-light .author .name:hover {
  color: #1f2d3d !important;
}
.nft.nft-light .bg-soft {
  background-color: rgba(31, 45, 61, 0.1) !important;
  border: 1px solid rgba(31, 45, 61, 0.1) !important;
  color: #1f2d3d !important;
}
.nft.nft-light .btn {
  background-color: #1f2d3d !important;
  border: 1px solid #1f2d3d !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(31, 45, 61, 0.1);
}
.nft.nft-light .btn:hover, .nft.nft-light .btn:focus, .nft.nft-light .btn:active, .nft.nft-light .btn.active, .nft.nft-light .btn.focus {
  background-color: #0e141b !important;
  border-color: #0e141b !important;
  color: #ffffff !important;
}

.nft.nft-dark .title:hover,
.nft.nft-dark .read-more:hover {
  color: #f8f9fa !important;
}
.nft.nft-dark .link {
  color: #f8f9fa !important;
}
.nft.nft-dark .author .name:hover {
  color: #f8f9fa !important;
}
.nft.nft-dark .bg-soft {
  background-color: rgba(248, 249, 250, 0.1) !important;
  border: 1px solid rgba(248, 249, 250, 0.1) !important;
  color: #f8f9fa !important;
}
.nft.nft-dark .btn {
  background-color: #f8f9fa !important;
  border: 1px solid #f8f9fa !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(248, 249, 250, 0.1);
}
.nft.nft-dark .btn:hover, .nft.nft-dark .btn:focus, .nft.nft-dark .btn:active, .nft.nft-dark .btn.active, .nft.nft-dark .btn.focus {
  background-color: #dae0e5 !important;
  border-color: #dae0e5 !important;
  color: #ffffff !important;
}

.nft.nft-footer .title:hover,
.nft.nft-footer .read-more:hover {
  color: #212f40 !important;
}
.nft.nft-footer .link {
  color: #212f40 !important;
}
.nft.nft-footer .author .name:hover {
  color: #212f40 !important;
}
.nft.nft-footer .bg-soft {
  background-color: rgba(33, 47, 64, 0.1) !important;
  border: 1px solid rgba(33, 47, 64, 0.1) !important;
  color: #212f40 !important;
}
.nft.nft-footer .btn {
  background-color: #212f40 !important;
  border: 1px solid #212f40 !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(33, 47, 64, 0.1);
}
.nft.nft-footer .btn:hover, .nft.nft-footer .btn:focus, .nft.nft-footer .btn:active, .nft.nft-footer .btn.active, .nft.nft-footer .btn.focus {
  background-color: #10161e !important;
  border-color: #10161e !important;
  color: #ffffff !important;
}

.nft.nft-muted .title:hover,
.nft.nft-muted .read-more:hover {
  color: #9bacc4 !important;
}
.nft.nft-muted .link {
  color: #9bacc4 !important;
}
.nft.nft-muted .author .name:hover {
  color: #9bacc4 !important;
}
.nft.nft-muted .bg-soft {
  background-color: rgba(155, 172, 196, 0.1) !important;
  border: 1px solid rgba(155, 172, 196, 0.1) !important;
  color: #9bacc4 !important;
}
.nft.nft-muted .btn {
  background-color: #9bacc4 !important;
  border: 1px solid #9bacc4 !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(155, 172, 196, 0.1);
}
.nft.nft-muted .btn:hover, .nft.nft-muted .btn:focus, .nft.nft-muted .btn:active, .nft.nft-muted .btn.active, .nft.nft-muted .btn.focus {
  background-color: #7b91b1 !important;
  border-color: #7b91b1 !important;
  color: #ffffff !important;
}

.nft.nft-item {
  transition: all 0.5s ease;
}
.nft.nft-item .nft-image .item-img {
  transition: all 0.5s ease;
}
.nft.nft-item .nft-image .overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: all 0.5s ease;
  background: #f8f9fa;
}
.nft.nft-item:hover {
  box-shadow: 0 5px 13px rgba(173, 181, 189, 0.2) !important;
}
.nft .bid-btn {
  position: absolute;
  transition: all 0.5s ease;
  bottom: -100px;
  right: 0;
  left: 0;
}
.nft.nft-creator {
  transition: all 0.5s ease;
}
.nft.nft-creator img {
  transition: all 0.5s ease;
}
.nft.nft-creator:hover img {
  height: 90px !important;
  width: 90px !important;
}
.nft:hover .nft-image .item-img {
  transform: scale(1.1) rotate(3deg);
}
.nft:hover .nft-image .overlay {
  opacity: 0.7;
}
.nft:hover .bid-btn {
  bottom: 0;
}

/*********************************/
/*         Home & Hero           */
/*===============================*/
.bg-home {
  height: 100vh;
  background-size: cover;
  align-self: center;
  position: relative;
  background-position: center center;
}

.bg-home-75vh {
  height: 75vh !important;
  background-size: cover;
  align-self: center;
  position: relative;
  background-position: center center;
}
@media (max-width: 767px) {
  .bg-home-75vh {
    height: 80vh !important;
  }
}

.bg-half-260 {
  padding: 260px 0;
  background-size: cover;
  align-self: center;
  position: relative;
  background-position: center center;
}

.bg-half-170 {
  padding: 170px 0;
  background-size: cover;
  align-self: center;
  position: relative;
  background-position: center center;
}

.bg-half-100 {
  padding: 100px 0;
  background-size: cover;
  align-self: center;
  position: relative;
  background-position: center center;
}

.bg-half {
  padding: 200px 0 100px;
  background-size: cover;
  align-self: center;
  position: relative;
  background-position: center center;
}
@media (max-width: 768px) {
  .bg-half {
    padding: 160px 0 60px;
  }
}

.bg-auth-home {
  padding: 142px 0;
  background-size: cover;
  align-self: center;
  position: relative;
  background-position: center center;
}

.bg-circle-gradiant {
  background: radial-gradient(circle at 50% 50%, rgba(47, 85, 212, 0.1) 0%, rgba(47, 85, 212, 0.1) 33.333%, rgba(47, 85, 212, 0.2) 33.333%, rgba(47, 85, 212, 0.2) 66.666%, rgba(47, 85, 212, 0.3) 66.666%, rgba(47, 85, 212, 0.3) 99.999%);
}

.it-home {
  animation: slideleft 30000s infinite linear;
}

@keyframes slideleft {
  from {
    background-position: 0%;
  }
  to {
    background-position: 90000%;
  }
}
.home-dashboard img {
  position: relative;
  top: 60px;
  z-index: 1;
}
@media (min-width: 768px) {
  .home-dashboard.onepage-hero {
    overflow: hidden;
  }
}

.classic-saas-image .bg-saas-shape:after {
  bottom: 3rem;
  left: 0;
  width: 75rem;
  height: 55rem;
  border-radius: 20rem;
}
@media (max-width: 767px) {
  .classic-saas-image .bg-saas-shape:after {
    left: 10rem;
    width: 30rem;
    height: 30rem;
    border-radius: 10rem;
  }
}

@media (max-width: 768px) {
  .classic-saas-image .bg-saas-shape img,
.freelance-hero .bg-shape img {
    max-width: 550px;
  }
}
@media (max-width: 767px) {
  .classic-saas-image .bg-saas-shape img,
.freelance-hero .bg-shape img {
    max-width: 100%;
    height: auto;
  }
}

.classic-saas-image .bg-saas-shape:after,
.freelance-hero .bg-shape:after {
  opacity: 0.9;
  transform: rotate(130deg);
}
@media (max-width: 767px) {
  .classic-saas-image .bg-saas-shape:after,
.freelance-hero .bg-shape:after {
    bottom: -5rem !important;
    height: 30rem;
  }
}

.freelance-hero .bg-shape:after {
  bottom: 0rem;
  left: 7rem;
  width: 100rem;
  height: 70rem;
  border-radius: 6rem;
}
@media (max-width: 768px) {
  .freelance-hero .bg-shape:after {
    bottom: -10rem;
  }
}
@media (max-width: 767px) {
  .freelance-hero .bg-shape:after {
    left: 15rem;
    width: 40rem;
    height: 30rem;
  }
}

.freelance-hero .bg-shape:after {
  box-shadow: 0 0 40px rgba(47, 85, 212, 0.5);
  background-color: #99abea;
}

.studio-home {
  padding: 7%;
}

.agency-wrapper:before {
  content: "";
  position: absolute;
  bottom: 22rem;
  right: 54rem;
  width: 90rem;
  height: 35rem;
  border-radius: 17rem;
  transform: rotate(45deg);
  background-color: #1f2d3d;
  z-index: -1;
}
@media (max-width: 768px) {
  .agency-wrapper:before {
    right: 12rem;
  }
}
@media (max-width: 767px) {
  .agency-wrapper:before {
    right: 0;
  }
}

.bg-marketing {
  padding: 76px 0 120px;
  background-size: cover;
  align-self: center;
  position: relative;
  background-position: center center;
}

.home-slider .carousel-control-next,
.home-slider .carousel-control-prev {
  width: 30px;
  height: 30px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 30px;
  border: 1px solid #2a3c51;
}
.home-slider .carousel-control-next-icon,
.home-slider .carousel-control-prev-icon {
  width: 30%;
}
.home-slider .carousel-control-next {
  right: 8px;
}
.home-slider .carousel-control-prev {
  left: 8px;
}

.carousel-indicators [data-bs-target] {
  width: 10px;
  height: 10px;
  border-radius: 3px;
  transition: all 0.5s ease;
  border: 0;
  background: #2f55d4 !important;
  margin: auto 4px;
}
.carousel-indicators .active {
  background-color: #2f55d4 !important;
  transform: rotate(45deg);
}

.bg-animation-left:after {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  width: 70%;
  height: 100%;
  background: rgba(28, 40, 54, 0.85);
  clip-path: polygon(0 0, 90% 0, 70% 100%, 0% 100%);
}
@media (max-width: 767px) {
  .bg-animation-left:after {
    width: 100%;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }
}
.bg-animation-left.dark-left:after {
  background: rgba(248, 249, 250, 0.6);
}
.bg-animation-left.crypto-home:after {
  background: #2f55d4;
  width: 80%;
  clip-path: polygon(0 0, 100% 0, 50% 100%, 0% 100%);
}
.bg-animation-left.task-management-home:after {
  background: url("../images/task/bg.png") #2f55d4;
  width: 100%;
  clip-path: circle(65% at 40% 25%);
}

.bg-invoice {
  padding: 100px 0;
  background-size: cover;
  align-self: center;
  position: relative;
  background-position: center center;
}

.classic-app-image .bg-app-shape:after {
  bottom: 3rem;
  left: -8rem;
  width: 55rem;
  height: 35rem;
  border-radius: 20rem;
  transform: rotate(120deg);
  opacity: 0.9;
}
.classic-app-image .app-images {
  position: absolute;
  bottom: 60px;
  right: -20px;
}

.classic-app-image .bg-app-shape:after,
.classic-saas-image .bg-saas-shape:after,
.freelance-hero .bg-shape:after {
  content: " ";
  position: absolute;
  z-index: -1;
}

.classic-app-image .bg-app-shape:after,
.classic-saas-image .bg-saas-shape:after {
  box-shadow: 0 0 40px rgba(47, 85, 212, 0.5);
  background-color: #2f55d4;
}

.shape-before .shape-img {
  position: absolute;
  top: -50px;
  left: -35px;
  z-index: -1;
  height: auto;
  overflow: hidden;
}

.swiper-slider-hero .swiper-container,
.swiper-slider-hero .swiper-slide .slide-inner {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.swiper-slider-hero .swiper-container .swiper-button-prev,
.swiper-slider-hero .swiper-container .swiper-button-next {
  background: transparent;
  width: 35px;
  height: 35px;
  line-height: 35px;
  margin-top: -30px;
}
.swiper-slider-hero .swiper-container .swiper-button-prev:before,
.swiper-slider-hero .swiper-container .swiper-button-next:before {
  font-family: "Material Design Icons";
  font-weight: 900;
  color: #ffffff;
}
.swiper-slider-hero .swiper-container .swiper-button-prev:hover,
.swiper-slider-hero .swiper-container .swiper-button-next:hover {
  background: #2f55d4;
  border-color: #2f55d4 !important;
}
.swiper-slider-hero .swiper-container .swiper-button-prev {
  left: 10px;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.swiper-slider-hero .swiper-container .swiper-button-prev:before {
  content: "\f0141";
}
.swiper-slider-hero .swiper-container .swiper-button-next {
  right: 10px;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.swiper-slider-hero .swiper-container .swiper-button-next:before {
  content: "\f0142";
}
.swiper-slider-hero .swiper-pagination-bullet {
  color: #1f2d3d;
  background: transparent;
}
.swiper-slider-hero .swiper-pagination-bullet-active {
  color: #ffffff;
}
.swiper-slider-hero .swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-slider-hero .swiper-pagination-custom,
.swiper-slider-hero .swiper-pagination-fraction {
  bottom: 45px;
}
.swiper-slider-hero .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 13px;
}

@media (min-width: 769px) {
  .bg-studio:after {
    content: "";
    position: absolute;
    right: 0px;
    top: 0px;
    width: 50%;
    height: 100%;
    background: #ffffff none repeat scroll 0 0;
    z-index: 2;
  }
}
@media (max-width: 767px) {
  .bg-home, .bg-half-170, .bg-half-260, .bg-marketing, .swiper-slider-hero .swiper-container .swiper-slide {
    padding: 150px 0;
    height: auto;
  }
}
.bg-video-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
  overflow: hidden;
}
.bg-video-wrapper iframe {
  width: 100vw;
  height: 56.25vw;
  /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
  min-height: 100vh;
  min-width: 177.77vh;
  /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.shape {
  position: absolute;
  pointer-events: none;
  right: 0;
  bottom: -2px;
  left: 0;
}
.shape > svg {
  transform: scale(2);
  width: 100%;
  height: auto;
  transform-origin: top center;
}
.shape.integration-hero {
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  height: 250px;
  background: #1c2836;
}
@media (max-width: 768px) {
  .shape.integration-hero {
    height: 140px;
  }
}
@media (max-width: 425px) {
  .shape.integration-hero {
    height: 60px;
  }
}
@media (max-width: 425px) {
  .shape {
    bottom: -4px;
  }
}

/*********************************/
/*         Pricing & Plans       */
/*===============================*/
.pricing.pricing-primary.business-rate:hover {
  border-color: #2f55d4 !important;
}
.pricing.pricing-primary.business-rate:hover .title {
  color: #2f55d4;
}
.pricing.pricing-primary.starter-plan {
  border-color: #2f55d4 !important;
}
.pricing.pricing-primary .name,
.pricing.pricing-primary .icon {
  color: #2f55d4;
}
.pricing.pricing-secondary.business-rate:hover {
  border-color: #ced4da !important;
}
.pricing.pricing-secondary.business-rate:hover .title {
  color: #ced4da;
}
.pricing.pricing-secondary.starter-plan {
  border-color: #ced4da !important;
}
.pricing.pricing-secondary .name,
.pricing.pricing-secondary .icon {
  color: #ced4da;
}
.pricing.pricing-success.business-rate:hover {
  border-color: #2eca8b !important;
}
.pricing.pricing-success.business-rate:hover .title {
  color: #2eca8b;
}
.pricing.pricing-success.starter-plan {
  border-color: #2eca8b !important;
}
.pricing.pricing-success .name,
.pricing.pricing-success .icon {
  color: #2eca8b;
}
.pricing.pricing-info.business-rate:hover {
  border-color: #17a2b8 !important;
}
.pricing.pricing-info.business-rate:hover .title {
  color: #17a2b8;
}
.pricing.pricing-info.starter-plan {
  border-color: #17a2b8 !important;
}
.pricing.pricing-info .name,
.pricing.pricing-info .icon {
  color: #17a2b8;
}
.pricing.pricing-warning.business-rate:hover {
  border-color: #f17425 !important;
}
.pricing.pricing-warning.business-rate:hover .title {
  color: #f17425;
}
.pricing.pricing-warning.starter-plan {
  border-color: #f17425 !important;
}
.pricing.pricing-warning .name,
.pricing.pricing-warning .icon {
  color: #f17425;
}
.pricing.pricing-danger.business-rate:hover {
  border-color: #e43f52 !important;
}
.pricing.pricing-danger.business-rate:hover .title {
  color: #e43f52;
}
.pricing.pricing-danger.starter-plan {
  border-color: #e43f52 !important;
}
.pricing.pricing-danger .name,
.pricing.pricing-danger .icon {
  color: #e43f52;
}
.pricing.pricing-light.business-rate:hover {
  border-color: #1f2d3d !important;
}
.pricing.pricing-light.business-rate:hover .title {
  color: #1f2d3d;
}
.pricing.pricing-light.starter-plan {
  border-color: #1f2d3d !important;
}
.pricing.pricing-light .name,
.pricing.pricing-light .icon {
  color: #1f2d3d;
}
.pricing.pricing-dark.business-rate:hover {
  border-color: #f8f9fa !important;
}
.pricing.pricing-dark.business-rate:hover .title {
  color: #f8f9fa;
}
.pricing.pricing-dark.starter-plan {
  border-color: #f8f9fa !important;
}
.pricing.pricing-dark .name,
.pricing.pricing-dark .icon {
  color: #f8f9fa;
}
.pricing.pricing-footer.business-rate:hover {
  border-color: #212f40 !important;
}
.pricing.pricing-footer.business-rate:hover .title {
  color: #212f40;
}
.pricing.pricing-footer.starter-plan {
  border-color: #212f40 !important;
}
.pricing.pricing-footer .name,
.pricing.pricing-footer .icon {
  color: #212f40;
}
.pricing.pricing-muted.business-rate:hover {
  border-color: #9bacc4 !important;
}
.pricing.pricing-muted.business-rate:hover .title {
  color: #9bacc4;
}
.pricing.pricing-muted.starter-plan {
  border-color: #9bacc4 !important;
}
.pricing.pricing-muted .name,
.pricing.pricing-muted .icon {
  color: #9bacc4;
}

.pricing {
  position: relative;
  z-index: 1;
  border-bottom: 3px solid #2a3c51 !important;
  transition: all 0.5s ease;
}
.pricing .price {
  font-weight: 400;
}
.pricing.business-rate:hover {
  transform: scale(1.03);
  z-index: 2;
  box-shadow: 0 5px 13px rgba(173, 181, 189, 0.2) !important;
  background-color: #1c2836 !important;
}
.pricing.starter-plan {
  transform: scale(1.05);
  z-index: 2;
}
@media (max-width: 767px) {
  .pricing.starter-plan {
    transform: scale(1);
  }
}
.pricing.best-plan:hover {
  transform: translateY(-10px);
}

.ribbon.ribbon-primary span {
  background-color: #2f55d4 !important;
}
.ribbon.ribbon-primary span:before {
  border-left-color: #2443ac;
  border-top-color: #2443ac;
}
.ribbon.ribbon-primary span:after {
  border-right-color: #2443ac;
  border-top-color: #2443ac;
}
.ribbon.ribbon-secondary span {
  background-color: #ced4da !important;
}
.ribbon.ribbon-secondary span:before {
  border-left-color: #b1bbc4;
  border-top-color: #b1bbc4;
}
.ribbon.ribbon-secondary span:after {
  border-right-color: #b1bbc4;
  border-top-color: #b1bbc4;
}
.ribbon.ribbon-success span {
  background-color: #2eca8b !important;
}
.ribbon.ribbon-success span:before {
  border-left-color: #25a06e;
  border-top-color: #25a06e;
}
.ribbon.ribbon-success span:after {
  border-right-color: #25a06e;
  border-top-color: #25a06e;
}
.ribbon.ribbon-info span {
  background-color: #17a2b8 !important;
}
.ribbon.ribbon-info span:before {
  border-left-color: #117a8b;
  border-top-color: #117a8b;
}
.ribbon.ribbon-info span:after {
  border-right-color: #117a8b;
  border-top-color: #117a8b;
}
.ribbon.ribbon-warning span {
  background-color: #f17425 !important;
}
.ribbon.ribbon-warning span:before {
  border-left-color: #d55b0e;
  border-top-color: #d55b0e;
}
.ribbon.ribbon-warning span:after {
  border-right-color: #d55b0e;
  border-top-color: #d55b0e;
}
.ribbon.ribbon-danger span {
  background-color: #e43f52 !important;
}
.ribbon.ribbon-danger span:before {
  border-left-color: #d21e32;
  border-top-color: #d21e32;
}
.ribbon.ribbon-danger span:after {
  border-right-color: #d21e32;
  border-top-color: #d21e32;
}
.ribbon.ribbon-light span {
  background-color: #1f2d3d !important;
}
.ribbon.ribbon-light span:before {
  border-left-color: #0e141b;
  border-top-color: #0e141b;
}
.ribbon.ribbon-light span:after {
  border-right-color: #0e141b;
  border-top-color: #0e141b;
}
.ribbon.ribbon-dark span {
  background-color: #f8f9fa !important;
}
.ribbon.ribbon-dark span:before {
  border-left-color: #dae0e5;
  border-top-color: #dae0e5;
}
.ribbon.ribbon-dark span:after {
  border-right-color: #dae0e5;
  border-top-color: #dae0e5;
}
.ribbon.ribbon-footer span {
  background-color: #212f40 !important;
}
.ribbon.ribbon-footer span:before {
  border-left-color: #10161e;
  border-top-color: #10161e;
}
.ribbon.ribbon-footer span:after {
  border-right-color: #10161e;
  border-top-color: #10161e;
}
.ribbon.ribbon-muted span {
  background-color: #9bacc4 !important;
}
.ribbon.ribbon-muted span:before {
  border-left-color: #7b91b1;
  border-top-color: #7b91b1;
}
.ribbon.ribbon-muted span:after {
  border-right-color: #7b91b1;
  border-top-color: #7b91b1;
}

.ribbon {
  position: absolute;
  top: -5px;
  width: 75px;
  height: 75px;
  z-index: 2;
}
.ribbon.ribbon-right {
  right: -5px;
}
.ribbon.ribbon-right span {
  right: -23px;
  transform: rotate(45deg);
}
.ribbon.ribbon-left {
  left: -5px;
}
.ribbon.ribbon-left span {
  left: -21px;
  transform: rotate(-45deg);
}
.ribbon span {
  position: absolute;
  top: 18px;
  width: 100px;
  color: #ffffff;
}
.ribbon span:before, .ribbon span:after {
  content: "";
  position: absolute;
  top: 100%;
  z-index: -1;
  border: 3px solid transparent;
}
.ribbon span:before {
  left: 0;
}
.ribbon span:after {
  right: 0;
}

.invoice-top .logo-invoice {
  font-weight: 700;
  font-size: 27px;
  letter-spacing: 1px;
}

.crypto-chart {
  position: absolute;
  left: 24px;
  right: 24px;
  bottom: 24px;
  opacity: 0.1;
  z-index: 0;
}

.calculator-block {
  background-color: #ffffff;
}
.calculator-block .cryptonatorwidget {
  border: none !important;
  padding: 0 !important;
  background: #1f2d3d !important;
}
.calculator-block .cryptonatorwidget input,
.calculator-block .cryptonatorwidget select {
  height: 40px !important;
  line-height: 40px !important;
  margin-bottom: 0;
  border: none;
  background-color: #1f2d3d !important;
  border: none;
  outline: none;
  box-shadow: 0 0 3px rgba(173, 181, 189, 0.15);
  padding: 8px 12px;
  border-radius: 6px;
  background-color: transparent;
  color: #9bacc4;
  font-family: var(--bs-font-sans-serif) !important;
}
.calculator-block .cryptonatorwidget div:last-child {
  display: none;
}
.calculator-block ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #9bacc4;
  text-transform: capitalize;
}
.calculator-block ::-moz-placeholder {
  /* Firefox 19+ */
  color: #9bacc4;
  text-transform: capitalize;
}
.calculator-block :-ms-input-placeholder {
  /* IE 10+ */
  color: #9bacc4;
  text-transform: capitalize;
}
.calculator-block :-moz-placeholder {
  /* Firefox 18- */
  color: #9bacc4;
  text-transform: capitalize;
}

.apexcharts-tooltip {
  background: #161c2d !important;
  color: #ffffff;
}

.switcher-pricing .toggle, .switcher-pricing .toggler {
  display: inline-block;
  vertical-align: middle;
}
.switcher-pricing .toggler {
  transition: all 0.5s ease;
  font-weight: bold;
}
.switcher-pricing .toggler--is-active {
  color: #2f55d4 !important;
}
.switcher-pricing .form-switch .form-check-input {
  width: 48px;
  height: 24px;
  margin-top: 0;
}

.hide {
  display: none;
}

/*********************************/
/*         Team members          */
/*===============================*/
.team.team-primary .name:hover {
  color: #2f55d4 !important;
}

.team.team-secondary .name:hover {
  color: #ced4da !important;
}

.team.team-success .name:hover {
  color: #2eca8b !important;
}

.team.team-info .name:hover {
  color: #17a2b8 !important;
}

.team.team-warning .name:hover {
  color: #f17425 !important;
}

.team.team-danger .name:hover {
  color: #e43f52 !important;
}

.team.team-light .name:hover {
  color: #1f2d3d !important;
}

.team.team-dark .name:hover {
  color: #f8f9fa !important;
}

.team.team-footer .name:hover {
  color: #212f40 !important;
}

.team.team-muted .name:hover {
  color: #9bacc4 !important;
}

.team img {
  transition: all 0.5s ease;
}
.team .team-icon {
  position: absolute;
  bottom: 50%;
  transform: translateY(50%);
  right: 0;
  opacity: 0;
  left: 0;
  text-align: center;
  transition: all 0.5s ease;
}
.team:hover img {
  box-shadow: 0 0 3px rgba(173, 181, 189, 0.15);
  opacity: 0.2;
}
.team:hover .overlay-team {
  opacity: 0.6;
}
.team:hover .team-icon {
  opacity: 1;
}

/*********************************/
/*         Testimonial           */
/*===============================*/
.client-testi {
  cursor: e-resize;
}
.client-testi .client-image {
  margin-right: 16px;
}
.client-testi .content:before {
  content: "";
  position: absolute;
  top: 30px;
  left: 0;
  margin-left: 13px;
  box-sizing: border-box;
  border: 8px solid #f8f9fa;
  border-color: transparent #1c2836 #1c2836 transparent;
  transform-origin: 0 0;
  transform: rotate(135deg);
  box-shadow: 2px 2px 2px -1px rgba(248, 249, 250, 0.15);
}

.tns-nav {
  text-align: center;
  margin-top: 10px;
}
.tns-nav button {
  border-radius: 3px;
  background: rgba(47, 85, 212, 0.5) !important;
  transition: all 0.5s ease;
  border: none;
  margin: 0 5px;
  padding: 5px;
}
.tns-nav button.tns-nav-active {
  background: #2f55d4 !important;
  transform: rotate(45deg);
}

.tns-controls button[data-controls=prev],
.tns-controls button[data-controls=next] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #ffffff;
  color: #f8f9fa;
  border: none;
  transition: all 0.5s ease;
  z-index: 1;
  box-shadow: 0 10px 25px rgba(173, 181, 189, 0.15);
}
.tns-controls button[data-controls=prev]:hover,
.tns-controls button[data-controls=next]:hover {
  background: #2f55d4;
  color: #ffffff;
}
.tns-controls button[data-controls=prev] {
  left: 0;
}
.tns-controls button[data-controls=next] {
  right: 0;
}

.slick-slider {
  overflow-x: hidden !important;
  cursor: pointer;
}

.tiny-timeline {
  position: relative;
  width: 100%;
  margin: 0 auto;
}
.tiny-timeline:before {
  content: "";
  position: absolute;
  width: 100%;
  top: 17px;
  left: 0;
  height: 2px;
  background: #2f55d4;
}
.tiny-timeline .item-box {
  margin: 70px 15px 20px;
}
.tiny-timeline .item-box:before, .tiny-timeline .item-box:after {
  content: "";
  position: absolute;
  left: 50%;
  background: #2f55d4;
}
.tiny-timeline .item-box:before {
  width: 2px;
  height: 46px;
  top: -46px;
  transform: translateX(-50%);
}
.tiny-timeline .item-box:after {
  width: 13px;
  height: 13px;
  top: -59px;
  border-radius: 3px;
  transform: translateX(-50%) rotate(45deg);
}

[dir=rtl] .swiper-slider-hero, [dir=rtl] .tns-inner, [dir=rtl] .tns-nav {
  direction: rtl;
}

/*********************************/
/*         Authentications       */
/*===============================*/
.login-page {
  font-size: 15px;
}
.login-page .forgot-pass {
  z-index: 99;
  position: relative;
  font-size: 13px;
}

.cover-user .container-fluid {
  max-width: 100%;
}
@media (max-width: 768px) {
  .cover-user .container-fluid .padding-less {
    position: relative !important;
    height: 400px !important;
  }
}
.cover-user .container-fluid .padding-less.img {
  background: scroll center no-repeat;
  background-size: cover;
  position: absolute;
  height: 100%;
}
.cover-user .cover-user-img {
  height: 100vh;
}
@media (min-width: 1025px) {
  .cover-user .cover-user-img {
    padding: 0px 20%;
  }
}
@media (max-width: 768px) {
  .cover-user .cover-user-img {
    align-items: start !important;
    height: auto !important;
    padding: 30px;
  }
}
@media (max-width: 768px) {
  .cover-user .cover-user-content {
    text-align: left !important;
  }
  .cover-user .cover-my-30 {
    margin: 30px 0;
  }
}

.form-signin {
  width: 100%;
  max-width: 400px;
  margin: auto;
}

.bg-profile {
  padding: 100px 0;
  background-size: cover;
  align-self: center;
  position: relative;
  background-position: center center;
}
.bg-profile .public-profile {
  top: 175px;
}

.chat .chat-list:hover, .chat .chat-list.active, .chat .chat-list:focus {
  background: #1f2d3d !important;
  box-shadow: 0 0 3px rgba(173, 181, 189, 0.15);
}
.chat .chat-list .on-off,
.chat .chat-type .on-off {
  position: absolute;
  left: -1px;
  top: -1px;
  font-size: 12px !important;
}
.chat.chat-person .on-off {
  font-size: 10px;
}
.chat.chat-person .chat-msg {
  margin-left: 8px;
}
.chat.chat-person .chat-msg .msg {
  border: 1px solid #2a3c51;
}
.chat.chat-person .chat-msg .msg-time {
  font-size: 12px !important;
}
.chat.chat-person .chat-right {
  justify-content: flex-end;
  text-align: right;
}
.chat.chat-person .chat-right .chat-user-image {
  order: 2;
}
.chat.chat-person .chat-right .chat-user-image .on-off {
  right: -1px;
}
.chat.chat-person .chat-right .chat-msg {
  margin-right: 8px;
  margin-left: 0;
}
.chat.chat-person .chat-right .chat-msg .msg {
  display: inline-block;
}
.chat.chat-person .chat-right .chat-msg .msg-time {
  display: block;
}

.simplebar-track.simplebar-vertical {
  width: 8px !important;
}
.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  width: 4px !important;
  background: #adb5bd !important;
}

.ck.ck-toolbar,
.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  background: #1c2836 !important;
  border: 1px solid #2a3c51 !important;
}

.ck.ck-toolbar > .ck-toolbar__items > :not(.ck-toolbar__line-break), .ck.ck-toolbar > .ck.ck-toolbar__grouped-dropdown {
  color: #f8f9fa;
}

/*********************************/
/*         Works & Portfolios    */
/*===============================*/
.work-container.work-primary .title:hover {
  color: #2f55d4 !important;
}
.work-container.work-primary.work-modern .work-icon {
  color: #2f55d4 !important;
}
.work-container.work-primary.work-modern .work-icon:hover {
  background: #2f55d4 !important;
}
.work-container.work-primary .btn {
  background-color: #2f55d4 !important;
  border: 1px solid #2f55d4 !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.1);
}
.work-container.work-primary .btn:hover, .work-container.work-primary .btn:focus, .work-container.work-primary .btn:active, .work-container.work-primary .btn.active, .work-container.work-primary .btn.focus {
  background-color: #2443ac !important;
  border-color: #2443ac !important;
  color: #ffffff !important;
}
.work-container.work-primary .bg {
  background-color: #2f55d4 !important;
}
.work-container.work-primary .link {
  color: #2f55d4 !important;
}

.work-container.work-secondary .title:hover {
  color: #ced4da !important;
}
.work-container.work-secondary.work-modern .work-icon {
  color: #ced4da !important;
}
.work-container.work-secondary.work-modern .work-icon:hover {
  background: #ced4da !important;
}
.work-container.work-secondary .btn {
  background-color: #ced4da !important;
  border: 1px solid #ced4da !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(206, 212, 218, 0.1);
}
.work-container.work-secondary .btn:hover, .work-container.work-secondary .btn:focus, .work-container.work-secondary .btn:active, .work-container.work-secondary .btn.active, .work-container.work-secondary .btn.focus {
  background-color: #b1bbc4 !important;
  border-color: #b1bbc4 !important;
  color: #ffffff !important;
}
.work-container.work-secondary .bg {
  background-color: #ced4da !important;
}
.work-container.work-secondary .link {
  color: #ced4da !important;
}

.work-container.work-success .title:hover {
  color: #2eca8b !important;
}
.work-container.work-success.work-modern .work-icon {
  color: #2eca8b !important;
}
.work-container.work-success.work-modern .work-icon:hover {
  background: #2eca8b !important;
}
.work-container.work-success .btn {
  background-color: #2eca8b !important;
  border: 1px solid #2eca8b !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(46, 202, 139, 0.1);
}
.work-container.work-success .btn:hover, .work-container.work-success .btn:focus, .work-container.work-success .btn:active, .work-container.work-success .btn.active, .work-container.work-success .btn.focus {
  background-color: #25a06e !important;
  border-color: #25a06e !important;
  color: #ffffff !important;
}
.work-container.work-success .bg {
  background-color: #2eca8b !important;
}
.work-container.work-success .link {
  color: #2eca8b !important;
}

.work-container.work-info .title:hover {
  color: #17a2b8 !important;
}
.work-container.work-info.work-modern .work-icon {
  color: #17a2b8 !important;
}
.work-container.work-info.work-modern .work-icon:hover {
  background: #17a2b8 !important;
}
.work-container.work-info .btn {
  background-color: #17a2b8 !important;
  border: 1px solid #17a2b8 !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(23, 162, 184, 0.1);
}
.work-container.work-info .btn:hover, .work-container.work-info .btn:focus, .work-container.work-info .btn:active, .work-container.work-info .btn.active, .work-container.work-info .btn.focus {
  background-color: #117a8b !important;
  border-color: #117a8b !important;
  color: #ffffff !important;
}
.work-container.work-info .bg {
  background-color: #17a2b8 !important;
}
.work-container.work-info .link {
  color: #17a2b8 !important;
}

.work-container.work-warning .title:hover {
  color: #f17425 !important;
}
.work-container.work-warning.work-modern .work-icon {
  color: #f17425 !important;
}
.work-container.work-warning.work-modern .work-icon:hover {
  background: #f17425 !important;
}
.work-container.work-warning .btn {
  background-color: #f17425 !important;
  border: 1px solid #f17425 !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(241, 116, 37, 0.1);
}
.work-container.work-warning .btn:hover, .work-container.work-warning .btn:focus, .work-container.work-warning .btn:active, .work-container.work-warning .btn.active, .work-container.work-warning .btn.focus {
  background-color: #d55b0e !important;
  border-color: #d55b0e !important;
  color: #ffffff !important;
}
.work-container.work-warning .bg {
  background-color: #f17425 !important;
}
.work-container.work-warning .link {
  color: #f17425 !important;
}

.work-container.work-danger .title:hover {
  color: #e43f52 !important;
}
.work-container.work-danger.work-modern .work-icon {
  color: #e43f52 !important;
}
.work-container.work-danger.work-modern .work-icon:hover {
  background: #e43f52 !important;
}
.work-container.work-danger .btn {
  background-color: #e43f52 !important;
  border: 1px solid #e43f52 !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(228, 63, 82, 0.1);
}
.work-container.work-danger .btn:hover, .work-container.work-danger .btn:focus, .work-container.work-danger .btn:active, .work-container.work-danger .btn.active, .work-container.work-danger .btn.focus {
  background-color: #d21e32 !important;
  border-color: #d21e32 !important;
  color: #ffffff !important;
}
.work-container.work-danger .bg {
  background-color: #e43f52 !important;
}
.work-container.work-danger .link {
  color: #e43f52 !important;
}

.work-container.work-light .title:hover {
  color: #1f2d3d !important;
}
.work-container.work-light.work-modern .work-icon {
  color: #1f2d3d !important;
}
.work-container.work-light.work-modern .work-icon:hover {
  background: #1f2d3d !important;
}
.work-container.work-light .btn {
  background-color: #1f2d3d !important;
  border: 1px solid #1f2d3d !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(31, 45, 61, 0.1);
}
.work-container.work-light .btn:hover, .work-container.work-light .btn:focus, .work-container.work-light .btn:active, .work-container.work-light .btn.active, .work-container.work-light .btn.focus {
  background-color: #0e141b !important;
  border-color: #0e141b !important;
  color: #ffffff !important;
}
.work-container.work-light .bg {
  background-color: #1f2d3d !important;
}
.work-container.work-light .link {
  color: #1f2d3d !important;
}

.work-container.work-dark .title:hover {
  color: #f8f9fa !important;
}
.work-container.work-dark.work-modern .work-icon {
  color: #f8f9fa !important;
}
.work-container.work-dark.work-modern .work-icon:hover {
  background: #f8f9fa !important;
}
.work-container.work-dark .btn {
  background-color: #f8f9fa !important;
  border: 1px solid #f8f9fa !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(248, 249, 250, 0.1);
}
.work-container.work-dark .btn:hover, .work-container.work-dark .btn:focus, .work-container.work-dark .btn:active, .work-container.work-dark .btn.active, .work-container.work-dark .btn.focus {
  background-color: #dae0e5 !important;
  border-color: #dae0e5 !important;
  color: #ffffff !important;
}
.work-container.work-dark .bg {
  background-color: #f8f9fa !important;
}
.work-container.work-dark .link {
  color: #f8f9fa !important;
}

.work-container.work-footer .title:hover {
  color: #212f40 !important;
}
.work-container.work-footer.work-modern .work-icon {
  color: #212f40 !important;
}
.work-container.work-footer.work-modern .work-icon:hover {
  background: #212f40 !important;
}
.work-container.work-footer .btn {
  background-color: #212f40 !important;
  border: 1px solid #212f40 !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(33, 47, 64, 0.1);
}
.work-container.work-footer .btn:hover, .work-container.work-footer .btn:focus, .work-container.work-footer .btn:active, .work-container.work-footer .btn.active, .work-container.work-footer .btn.focus {
  background-color: #10161e !important;
  border-color: #10161e !important;
  color: #ffffff !important;
}
.work-container.work-footer .bg {
  background-color: #212f40 !important;
}
.work-container.work-footer .link {
  color: #212f40 !important;
}

.work-container.work-muted .title:hover {
  color: #9bacc4 !important;
}
.work-container.work-muted.work-modern .work-icon {
  color: #9bacc4 !important;
}
.work-container.work-muted.work-modern .work-icon:hover {
  background: #9bacc4 !important;
}
.work-container.work-muted .btn {
  background-color: #9bacc4 !important;
  border: 1px solid #9bacc4 !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(155, 172, 196, 0.1);
}
.work-container.work-muted .btn:hover, .work-container.work-muted .btn:focus, .work-container.work-muted .btn:active, .work-container.work-muted .btn.active, .work-container.work-muted .btn.focus {
  background-color: #7b91b1 !important;
  border-color: #7b91b1 !important;
  color: #ffffff !important;
}
.work-container.work-muted .bg {
  background-color: #9bacc4 !important;
}
.work-container.work-muted .link {
  color: #9bacc4 !important;
}

.work-container.work-classic .work-image {
  transition: all 0.5s ease;
}
.work-container.work-classic .work-image:hover {
  box-shadow: 0 0 3px rgba(173, 181, 189, 0.15);
}
.work-container .overlay-work {
  background-color: #161c2d;
}
.work-container.work-classic .content .title, .work-container.work-grid .content .title, .work-container.work-modern .content .title {
  font-size: 18px;
  transition: all 0.5s ease;
}
.work-container.work-classic .content .tag, .work-container.work-grid .content .tag, .work-container.work-modern .content .tag {
  font-size: 15px !important;
}
.work-container.work-modern img,
.work-container.work-modern .overlay-work,
.work-container.work-modern .content,
.work-container.work-modern .client,
.work-container.work-modern .read_more,
.work-container.work-modern .icons,
.work-container.work-modern .icons .work-icon {
  transition: all 0.5s ease;
}
.work-container.work-modern .overlay-work,
.work-container.work-modern .content,
.work-container.work-modern .client,
.work-container.work-modern .read_more,
.work-container.work-modern .icons {
  position: absolute;
}
.work-container.work-modern .overlay-work {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
}
.work-container.work-modern .content {
  z-index: 1;
  bottom: 5%;
  left: 5%;
}
.work-container.work-modern .content,
.work-container.work-modern .client {
  opacity: 0;
}
.work-container.work-modern .client,
.work-container.work-modern .read_more {
  z-index: 1;
  right: 5%;
  top: 5%;
}
.work-container.work-modern .read_more {
  height: 30px;
  width: 30px;
  line-height: 30px;
}
.work-container.work-modern .icons {
  right: 0;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  opacity: 0;
}
.work-container.work-modern .icons .work-icon {
  height: 45px;
  width: 45px;
  line-height: 45px;
}
.work-container.work-modern .icons .work-icon:hover {
  color: #ffffff !important;
}
.work-container.work-modern:hover img {
  transform: scale(1.1) rotate(3deg);
}
.work-container.work-modern:hover .overlay-work {
  opacity: 0.65;
}
.work-container.work-modern:hover .icons {
  opacity: 1;
}
.work-container.work-modern:hover .personal-port,
.work-container.work-modern:hover .content,
.work-container.work-modern:hover .client {
  opacity: 1;
}
.work-container.work-grid {
  transition: all 0.5s ease;
}
.work-container.work-grid img, .work-container.work-grid .content, .work-container.work-grid .content .title {
  transition: all 0.5s ease;
}
.work-container.work-grid .content {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  transform: translateY(150px);
  overflow: hidden;
  background-color: #161c2d;
}
.work-container.work-grid:hover {
  box-shadow: 0 0 3px rgba(173, 181, 189, 0.15);
}
.work-container.work-grid:hover img {
  transform: translateY(-40px);
}
.work-container.work-grid:hover .content {
  transform: translateY(0);
}

.work-details ul li b {
  max-width: 90px;
  width: 100%;
  display: inline-block;
}

.container-filter li {
  font-size: 13px;
  padding: 0px 15px;
  margin: 5px 3px;
  font-weight: 700;
  letter-spacing: 0.8px;
  cursor: pointer;
  line-height: 34px;
  transition: all 0.5s ease;
}
.container-filter li.active, .container-filter li:hover {
  color: #2f55d4 !important;
  border-color: #2f55d4 !important;
}

.spacing {
  padding: 7px;
}

/*********************************/
/*      Travel                   */
/*===============================*/
.popular-tour .content {
  position: absolute;
  bottom: 3%;
  left: 5%;
}
.popular-tour .overlay-work {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: all 0.5s ease;
}
.popular-tour:hover .overlay-work {
  opacity: 0.6;
}

/*********************************/
/*         Events                */
/*===============================*/
.event-schedule.event-primary .date {
  color: #2f55d4 !important;
}
.event-schedule.event-primary .date .day {
  background: rgba(47, 85, 212, 0.1);
  box-shadow: 0px 0px 2px 0.25px rgba(47, 85, 212, 0.5);
}
.event-schedule.event-primary .content .title:hover {
  color: #2f55d4 !important;
}
.event-schedule.event-primary:hover {
  border-color: #2f55d4 !important;
}

.event-schedule.event-secondary .date {
  color: #ced4da !important;
}
.event-schedule.event-secondary .date .day {
  background: rgba(206, 212, 218, 0.1);
  box-shadow: 0px 0px 2px 0.25px rgba(206, 212, 218, 0.5);
}
.event-schedule.event-secondary .content .title:hover {
  color: #ced4da !important;
}
.event-schedule.event-secondary:hover {
  border-color: #ced4da !important;
}

.event-schedule.event-success .date {
  color: #2eca8b !important;
}
.event-schedule.event-success .date .day {
  background: rgba(46, 202, 139, 0.1);
  box-shadow: 0px 0px 2px 0.25px rgba(46, 202, 139, 0.5);
}
.event-schedule.event-success .content .title:hover {
  color: #2eca8b !important;
}
.event-schedule.event-success:hover {
  border-color: #2eca8b !important;
}

.event-schedule.event-info .date {
  color: #17a2b8 !important;
}
.event-schedule.event-info .date .day {
  background: rgba(23, 162, 184, 0.1);
  box-shadow: 0px 0px 2px 0.25px rgba(23, 162, 184, 0.5);
}
.event-schedule.event-info .content .title:hover {
  color: #17a2b8 !important;
}
.event-schedule.event-info:hover {
  border-color: #17a2b8 !important;
}

.event-schedule.event-warning .date {
  color: #f17425 !important;
}
.event-schedule.event-warning .date .day {
  background: rgba(241, 116, 37, 0.1);
  box-shadow: 0px 0px 2px 0.25px rgba(241, 116, 37, 0.5);
}
.event-schedule.event-warning .content .title:hover {
  color: #f17425 !important;
}
.event-schedule.event-warning:hover {
  border-color: #f17425 !important;
}

.event-schedule.event-danger .date {
  color: #e43f52 !important;
}
.event-schedule.event-danger .date .day {
  background: rgba(228, 63, 82, 0.1);
  box-shadow: 0px 0px 2px 0.25px rgba(228, 63, 82, 0.5);
}
.event-schedule.event-danger .content .title:hover {
  color: #e43f52 !important;
}
.event-schedule.event-danger:hover {
  border-color: #e43f52 !important;
}

.event-schedule.event-light .date {
  color: #1f2d3d !important;
}
.event-schedule.event-light .date .day {
  background: rgba(31, 45, 61, 0.1);
  box-shadow: 0px 0px 2px 0.25px rgba(31, 45, 61, 0.5);
}
.event-schedule.event-light .content .title:hover {
  color: #1f2d3d !important;
}
.event-schedule.event-light:hover {
  border-color: #1f2d3d !important;
}

.event-schedule.event-dark .date {
  color: #f8f9fa !important;
}
.event-schedule.event-dark .date .day {
  background: rgba(248, 249, 250, 0.1);
  box-shadow: 0px 0px 2px 0.25px rgba(248, 249, 250, 0.5);
}
.event-schedule.event-dark .content .title:hover {
  color: #f8f9fa !important;
}
.event-schedule.event-dark:hover {
  border-color: #f8f9fa !important;
}

.event-schedule.event-footer .date {
  color: #212f40 !important;
}
.event-schedule.event-footer .date .day {
  background: rgba(33, 47, 64, 0.1);
  box-shadow: 0px 0px 2px 0.25px rgba(33, 47, 64, 0.5);
}
.event-schedule.event-footer .content .title:hover {
  color: #212f40 !important;
}
.event-schedule.event-footer:hover {
  border-color: #212f40 !important;
}

.event-schedule.event-muted .date {
  color: #9bacc4 !important;
}
.event-schedule.event-muted .date .day {
  background: rgba(155, 172, 196, 0.1);
  box-shadow: 0px 0px 2px 0.25px rgba(155, 172, 196, 0.5);
}
.event-schedule.event-muted .content .title:hover {
  color: #9bacc4 !important;
}
.event-schedule.event-muted:hover {
  border-color: #9bacc4 !important;
}

.event-schedule {
  transition: all 0.5s ease;
}
.event-schedule .date .day {
  font-size: 18px;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  line-height: 48px;
  border: 2px solid #2a3c51;
}
.event-schedule .date .month {
  font-size: 13px;
}
.event-schedule .content .title {
  font-size: 20px;
  transition: all 0.5s ease;
}
.event-schedule .content .location-time {
  font-size: 14px;
}
.event-schedule:hover {
  transform: translateY(-5px);
  box-shadow: 0 0 3px rgba(173, 181, 189, 0.15);
}

/*********************************/
/*         Shopping              */
/*===============================*/
.shop-features .category-title {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  z-index: 1;
}

.shop-list .shop-image .overlay-work,
.shop-list .shop-image .shop-icons {
  position: absolute;
  opacity: 0;
  transition: all 0.5s ease;
}
.shop-list .shop-image .overlay-work {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.shop-list .shop-image .overlay-work .out-stock {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.shop-list .shop-image .shop-icons {
  top: 15px;
  right: 15px;
  z-index: 2;
}
.shop-list .shop-image:hover .overlay-work,
.shop-list .shop-image:hover .shop-icons {
  opacity: 1;
}
.shop-list .content .product-name {
  transition: all 0.5s ease;
}
.shop-list .content .product-name:hover {
  color: #2f55d4 !important;
}
.shop-list .label {
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 2;
}
.shop-list .qty-btn {
  pointer-events: none;
  width: 65px;
  padding-left: 15px;
}

/*********************************/
/*         Timeline              */
/*===============================*/
.main-icon {
  border: 2px solid #2a3c51;
  height: 40px;
  width: 40px;
  line-height: 0;
  text-align: center;
}
.main-icon .fea {
  height: 20px;
}
@media (min-width: 768px) {
  .main-icon {
    margin: 0 auto;
  }
}

.timeline-page:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 95%;
  margin: auto;
  width: 2px;
  background-color: #2a3c51 !important;
}
.timeline-page .timeline-item .date-label-left, .timeline-page .timeline-item .duration-right {
  margin-right: 15px;
}
.timeline-page .timeline-item .date-label-left:after, .timeline-page .timeline-item .duration-right:after {
  content: "";
  position: absolute;
  top: 12px;
  width: 12px;
  height: 12px;
  z-index: 1;
  background-color: #2f55d4;
  border-radius: 4px;
  transform: rotate(45deg);
}
.timeline-page .timeline-item .date-label-left {
  float: right;
  margin-right: 15px;
}
.timeline-page .timeline-item .date-label-left:after {
  right: -34px;
}
.timeline-page .timeline-item .duration-right {
  float: left;
  margin-left: 15px;
}
.timeline-page .timeline-item .duration-right:after {
  left: -34px;
}
.timeline-page .timeline-item .event {
  transition: all 0.2s ease;
}
.timeline-page .timeline-item .event.event-description-right {
  margin-left: 15px;
}
.timeline-page .timeline-item .event.event-description-left {
  margin-right: 15px;
  text-align: right;
}

@media (max-width: 767px) {
  .timeline-page {
    margin-left: 18px;
  }
  .timeline-page:after {
    margin: 0;
  }
  .timeline-page .timeline-item .duration {
    float: left !important;
    margin: 0 0 30px 15px !important;
    text-align: left !important;
  }
  .timeline-page .timeline-item .duration:after {
    left: -21px !important;
  }
  .timeline-page .timeline-item .duration .event {
    text-align: left !important;
    margin-left: 15px;
  }
  .timeline-page .timeline-item .event-description-left {
    text-align: left !important;
    margin: 0 0 0 15px;
  }
}
/*********************************/
/*         Tobii Lightbox        */
/*===============================*/
.tobii > button.tobii__close svg,
.tobii > button.tobii__prev svg,
.tobii > button.tobii__next svg {
  height: 36px;
  width: auto;
}

.tobii__counter {
  font-size: 16px;
}

.tobii-zoom {
  display: block !important;
}

.tobii-zoom__icon {
  display: none;
}

#grid {
  padding: 0 !important;
}

.fw-medium {
  font-weight: 600;
}

.section {
  padding: 100px 0;
  position: relative;
}
@media (max-width: 768px) {
  .section {
    padding: 60px 0;
  }
}

.section-two {
  padding: 60px 0;
  position: relative;
}

.bg-overlay {
  background-color: rgba(28, 40, 54, 0.7);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.bg-overlay-white {
  background-color: rgba(0, 0, 0, 0.4);
}

.bg-gradient-primary {
  background: linear-gradient(to left, #59339d 0%, #0257d5 100%);
}

.bg-linear-gradient {
  background: linear-gradient(to bottom, rgba(22, 28, 45, 0) 0%, rgba(22, 28, 45, 0) 50%, rgba(22, 28, 45, 0.3) 80%, #161c2d 100%);
}

.bg-linear-gradient-2 {
  background: linear-gradient(to bottom, rgba(22, 28, 45, 0) 0%, rgba(22, 28, 45, 0.3) 50%, rgba(22, 28, 45, 0.7) 75%, #161c2d 100%);
}

.bg-linear-gradient-3 {
  background: linear-gradient(to right, #161c2d 0%, #161c2d 25%, #161c2d 50%, rgba(22, 28, 45, 0.5) 75%, rgba(22, 28, 45, 0) 100%);
}

.bg-gradient-overlay {
  background: linear-gradient(to bottom, rgba(22, 28, 45, 0) 0%, rgba(22, 28, 45, 0.5) 25%, rgba(22, 28, 45, 0.75) 50%, #161c2d 100%);
}

.bg-gradient-overlay-2 {
  background: linear-gradient(to bottom, rgba(22, 28, 45, 0.5) 0%, rgba(22, 28, 45, 0.6) 25%, rgba(22, 28, 45, 0.7) 50%, rgba(22, 28, 45, 0.8) 100%);
}

.bg-primary-gradient-overlay {
  background: linear-gradient(to bottom, rgba(13, 110, 253, 0) 0%, rgba(13, 110, 253, 0.25) 25%, rgba(13, 110, 253, 0.5) 50%, rgba(13, 110, 253, 0.75) 75%, #0d6efd 100%);
}

.bg-gradient-white-overlay {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.5) 100%);
}

.title-heading {
  line-height: 26px;
}
.title-heading .heading {
  font-size: 45px !important;
  letter-spacing: 1px;
}
@media (max-width: 768px) {
  .title-heading .heading {
    font-size: 35px !important;
  }
}
.title-heading .para-desc {
  font-size: 18px;
}

.section-title .title {
  letter-spacing: 0.5px;
  font-size: 30px !important;
}
@media (max-width: 768px) {
  .section-title .title {
    font-size: 24px !important;
  }
}

.text-shadow-title {
  text-shadow: 2px 0 0 #ffffff, -2px 0 0 #ffffff, 0 4px 0 rgba(255, 255, 255, 0.4), 0 -2px 0 #ffffff, 1px 1px #ffffff, -1px -1px 0 #ffffff, 1px -1px 0 #ffffff, -1px 1px 0 #ffffff;
}

.para-desc {
  max-width: 600px;
}

.mt-100 {
  margin-top: 100px;
}

.text-slider {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}
.text-slider > ul {
  white-space: nowrap;
}
.text-slider .move-text {
  animation: moveSlideshow 12s linear infinite;
}

@keyframes moveSlideshow {
  100% {
    transform: translateX(-45%);
  }
}
.mover {
  animation: mover 1s infinite alternate;
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(5px);
  }
}
.feature-posts-placeholder {
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  height: 66.6%;
}
@media (max-width: 425px) {
  .feature-posts-placeholder {
    height: 80%;
  }
}

.spin-anything {
  animation: spinner-animation 5s linear infinite;
}

@keyframes spinner-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.seo-hero-widget {
  position: relative;
  transition: all 0.5s ease;
}
.seo-hero-widget:hover {
  transform: scale(1.05);
  z-index: 9;
}

.home-wrapper:before {
  content: "";
  position: absolute;
  bottom: 40rem;
  right: 0rem;
  width: 90rem;
  height: 35rem;
  transform: rotate(78.75deg);
  background: linear-gradient(90deg, rgba(47, 85, 212, 0.3), transparent);
  z-index: 1;
}
@media (min-width: 769px) and (max-width: 1024px) {
  .home-wrapper:before {
    right: -20rem;
  }
}
@media (min-width: 426px) and (max-width: 768px) {
  .home-wrapper:before {
    right: -15rem;
    width: 70rem;
    height: 25rem;
  }
}
@media (max-width: 425px) {
  .home-wrapper:before {
    right: -25rem;
  }
}

.cookie-popup {
  position: fixed;
  bottom: 12px;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 512px;
  z-index: 5;
}
.cookie-popup .cookie-popup-actions button {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  font-weight: 600;
  position: absolute;
  top: 5px;
  right: 5px;
}
@media (max-width: 425px) {
  .cookie-popup {
    left: 12px;
    right: 12px;
  }
}

.cookie-popup-not-accepted {
  display: block;
  animation: cookie-popup-in 0.5s ease forwards;
}

.cookie-popup-accepted {
  display: none !important;
}

@keyframes cookie-popup-in {
  from {
    bottom: -6.25rem;
  }
  to {
    bottom: 1.25rem;
  }
}
.clip-text {
  font-size: 190px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.clip-text.clip-text-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 1024px) {
  .clip-text {
    font-size: 180px;
  }
}
@media (max-width: 768px) {
  .clip-text {
    font-size: 135px;
  }
}
@media (max-width: 425px) {
  .clip-text {
    font-size: 50px;
  }
}

@media (min-width: 768px) {
  .margin-top-100 {
    margin-top: 100px;
  }
}
@media (max-width: 768px) {
  .mt-60 {
    margin-top: 60px;
  }
}
.background-effect .circles {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.background-effect .circles li {
  position: absolute;
  display: block;
  animation: animate 25s linear infinite;
  bottom: -150px;
  background: rgba(47, 85, 212, 0.15);
}
.background-effect .circles li:nth-child(1), .background-effect .circles li:nth-child(2), .background-effect .circles li:nth-child(3), .background-effect .circles li:nth-child(4), .background-effect .circles li:nth-child(5), .background-effect .circles li:nth-child(6), .background-effect .circles li:nth-child(7), .background-effect .circles li:nth-child(8), .background-effect .circles li:nth-child(9), .background-effect .circles li:nth-child(10) {
  width: 24px;
  height: 24px;
}
.background-effect .circles li:nth-child(1) {
  left: 25%;
  animation-delay: 0s;
}
.background-effect .circles li:nth-child(2) {
  left: 10%;
  animation-delay: 2s;
  animation-duration: 12s;
}
.background-effect .circles li:nth-child(3) {
  left: 70%;
  animation-delay: 4s;
}
.background-effect .circles li:nth-child(4) {
  left: 40%;
  animation-delay: 0s;
  animation-duration: 18s;
}
.background-effect .circles li:nth-child(5) {
  left: 65%;
  animation-delay: 0s;
}
.background-effect .circles li:nth-child(6) {
  left: 75%;
  animation-delay: 3s;
}
.background-effect .circles li:nth-child(7) {
  left: 35%;
  animation-delay: 7s;
}
.background-effect .circles li:nth-child(8) {
  left: 50%;
  animation-delay: 15s;
  animation-duration: 45s;
}
.background-effect .circles li:nth-child(9) {
  left: 20%;
  animation-delay: 2s;
  animation-duration: 35s;
}
.background-effect .circles li:nth-child(10) {
  left: 85%;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 10px;
  }
  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
  }
}
.fea {
  stroke-width: 1.8;
}
.fea.icon-sm {
  height: 16px;
  width: 16px;
}
.fea.icon-ex-md {
  height: 20px;
  min-width: 20px;
}
.fea.icon-m-md {
  height: 28px;
  width: 28px;
}
.fea.icon-md {
  height: 35px;
  width: 35px;
}
.fea.icon-lg {
  height: 42px;
  width: 42px;
}
.fea.icon-ex-lg {
  height: 52px;
  width: 52px;
}

[class^=uil-]:before,
[class*=" uil-"]:before {
  margin: 0;
}

.uim-svg {
  vertical-align: 0 !important;
}

.rounded {
  border-radius: 6px !important;
}

.rounded-top {
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
}

.rounded-left {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}

.rounded-bottom {
  border-bottom-left-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

.rounded-right {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

.rounded-md {
  border-radius: 10px !important;
}

.rounded-lg {
  border-radius: 30px !important;
}

.border-primary {
  border-color: #2f55d4 !important;
}

.border-secondary {
  border-color: #ced4da !important;
}

.border-success {
  border-color: #2eca8b !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #f17425 !important;
}

.border-danger {
  border-color: #e43f52 !important;
}

.border-light {
  border-color: #1f2d3d !important;
}

.border-dark {
  border-color: #f8f9fa !important;
}

.border-footer {
  border-color: #212f40 !important;
}

.border-muted {
  border-color: #9bacc4 !important;
}

.opacity-05 {
  opacity: 0.05;
}

.opacity-075 {
  opacity: 0.075;
}

.opacity-1 {
  opacity: 0.1;
}

.opacity-2 {
  opacity: 0.2;
}

.opacity-3 {
  opacity: 0.3;
}

.opacity-4 {
  opacity: 0.4;
}

.opacity-5 {
  opacity: 0.5;
}

.opacity-6 {
  opacity: 0.6;
}

.opacity-7 {
  opacity: 0.7;
}

.opacity-8 {
  opacity: 0.8;
}

.opacity-9 {
  opacity: 0.9;
}

.z-index-1 {
  z-index: 1;
}

.z-index-0 {
  z-index: 0;
}

.z-index-m-1 {
  z-index: -1;
}

.small, small {
  font-size: 90%;
}

.social-icon li a {
  color: #f8f9fa;
  border: 1px solid #f8f9fa;
  height: 32px;
  width: 32px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: all 0.4s ease;
  overflow: hidden;
  position: relative;
}
.social-icon li a .fea-social {
  stroke-width: 2;
}
.social-icon li a:hover {
  background-color: #2f55d4;
  border-color: #2f55d4 !important;
  color: #fff !important;
}
.social-icon li a:hover .fea-social {
  fill: #2f55d4;
}
.social-icon.social li a {
  color: #adb5bd;
  border-color: #2a3b51;
}
.social-icon.foot-social-icon li a {
  color: #adb5bd;
  border-color: #2a3b51;
}

.back-to-top {
  position: fixed;
  z-index: 99;
  bottom: 30px;
  right: 30px;
  display: none;
  transition: all 0.5s ease;
  height: 36px;
  width: 36px;
  line-height: 33px;
  border-radius: 6px;
  text-align: center;
  background: #2f55d4;
  color: #fff !important;
}
.back-to-top .icons {
  transition: all 0.5s ease;
}
.back-to-top:hover {
  transform: rotate(45deg);
}
.back-to-top:hover .icons {
  transform: rotate(-45deg) !important;
}

.back-to-home {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 1;
}

.sticky-bar {
  position: sticky;
  top: 80px;
}

.position-middle-bottom {
  position: absolute;
  bottom: 15px;
  left: 12px;
  right: 12px;
  text-align: center;
}

.text-color-white {
  color: #1c2836 !important;
}

.d-flex .flex-1 {
  flex: 1;
}

.switcher-btn {
  position: fixed;
  top: 200px;
  height: 41px;
  width: 40px;
  padding: 3px;
  border-radius: 0 30px 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.style-switcher .t-dark,
.style-switcher .t-rtl-light {
  display: inline-block;
}
.style-switcher .t-ltr-light,
.style-switcher .t-light,
.style-switcher .t-rtl-dark,
.style-switcher .t-ltr-dark {
  display: none;
}

@media only screen and (max-width: 375px) {
  .switcher-btn {
    display: none !important;
  }
}
[dir=rtl] .style-switcher .t-rtl-light {
  display: none;
}
[dir=rtl] .style-switcher .t-ltr-light {
  display: inline-block;
}

#topnav .logo .l-dark,
#topnav .logo .logo-light-mode {
  display: none !important;
}
#topnav .logo .l-light,
#topnav .logo .logo-dark-mode {
  display: inline-block !important;
}

.style-switcher .t-dark,
.style-switcher .t-rtl-light,
.style-switcher .t-ltr-dark {
  display: none;
}
.style-switcher .t-light,
.style-switcher .t-rtl-dark {
  display: inline-block;
}

[dir=rtl] .style-switcher .t-rtl-dark,
[dir=rtl] .style-switcher .t-ltr-light {
  display: none;
}
[dir=rtl] .style-switcher .t-ltr-dark {
  display: inline-block;
}

.offcanvas .offcanvas-header .dark-version {
  display: inline-block;
}
.offcanvas .offcanvas-header .light-version {
  display: none;
}
.offcanvas .offcanvas-header .btn-close {
  color: #f8f9fa;
}